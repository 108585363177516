import React from 'react';
import Button from 'components/button';
import { withRouter } from 'react-router-dom';

const GoBackButton = ({ history, staticContext, ...props }) => (
  <Button {...props} onClick={() => history.goBack()}>
    <i className="fas fa-arrow-left back__icon" />
    Go back
  </Button>
);

export default withRouter(GoBackButton);
