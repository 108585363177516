import React, { useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import Button from 'components/button';
import InputVotes from 'components/entryForm/InputVotes';
import 'components/popup/popup.scss';
import 'components/votesEdit/votesEdit.scss';
import { toast } from 'react-toastify';
import { LIMIT_VOTES_PER_USER } from 'root/constants';
import { useSelector } from 'react-redux';

const VotesEdit = ({ handleSetMaxVotesNumber }) => {
  const votesPerUser = useSelector((state) => state.board.votesPerUser);
  const [currentMaxVotes, setCurrentMaxVotes] = useState('');
  const onSubmit = (close) => {
    if (currentMaxVotes && currentMaxVotes <= LIMIT_VOTES_PER_USER) {
      handleSetMaxVotesNumber(currentMaxVotes);
      close();
    } else {
      toast.warn(
        `Votes number should be greater than 0 and less than ${LIMIT_VOTES_PER_USER}`
      );
    }
  };

  return (
    <Popup
      className="votesEdit-popup popup-content--yellow"
      trigger={
        <Button className="button button--white">
          <i className="fas fa-thumbs-up card__action--icon" /> Set votes per
          user
        </Button>
      }
      onOpen={() => {
        setCurrentMaxVotes(votesPerUser);
      }}
      modal
    >
      {(close) => (
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Set max votes per user:</p>
            <Button className="delete" aria-label="close" onClick={close} />
          </header>
          <div className="content">
            <div className="field">
              <div className="control">
                <InputVotes
                  maxVotes={currentMaxVotes}
                  setMaxVotes={setCurrentMaxVotes}
                />
              </div>
            </div>
          </div>
          <footer className="modal-card-foot">
            <Button
              className="button--medium button--yellow"
              onClick={() => onSubmit(close)}
              disabled={!currentMaxVotes}
            >
              Save changes
            </Button>
          </footer>
        </div>
      )}
    </Popup>
  );
};

export default VotesEdit;
