import { curry } from 'lodash/fp';
import SignalRConnection from 'root/signalR';
import { setToken, removeToken, getToken } from 'root/jwt-helpers';
import { getUserName } from 'root/userNameHelpers';

export const connection = new SignalRConnection();

export const isFullAppVersion = () => process.env.APP_MODE === 'full';

export const apiAuthorizedCall = async (url, method = 'GET') => {
  try {
    const response = fetch(`${API_URL}${url}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
        'Expect-CT': 'max-age=180, enforce',
        'Feature-Policy': "microphone 'none'; camera 'none'",
        'X-Frame-Options': 'DENY',
        'X-Content-Type-Options': 'nosniff',
        'X-XSS-Protection': '1; mode=block',
      },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveBoard = (boardId) =>
  apiAuthorizedCall(`/api/archive/save?boardId=${boardId}`, 'POST');

export const downloadBoard = (type, boardId) =>
  apiAuthorizedCall(`/api/export/${type}?boardId=${boardId}`);

export const fetchBoards = async () => {
  try {
    const boards = await apiAuthorizedCall('/api/boards');
    if (boards) {
      return await boards.json();
    }
  } catch (error) {
    return [];
  }
};

export const userLoginByGoogle = async (token) => {
  const request = await fetch(`${API_URL}/api/user/google`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Expect-CT': 'max-age=180, enforce',
      'Feature-Policy': "microphone 'none'; camera 'none'",
      'X-Frame-Options': 'DENY',
      'X-Content-Type-Options': 'nosniff',
      'X-XSS-Protection': '1; mode=block',
    },
    body: JSON.stringify({ tokenId: token }),
  });

  if (request.ok) {
    const jwtToken = await request.json();
    setToken(jwtToken.token);
    connection.signal.invoke('Authenticate', getToken());
    return true;
  }
  return false;
};

export const userLoggedOut = () => removeToken();

const apiAddCard = async (boardId, columnId, text) =>
  await connection.signal.invoke('AddCard', boardId, columnId, text);

const apiAddVote = async (boardId, columnId, cardId) =>
  await connection.signal.invoke('AddVote', boardId, columnId, cardId);

const apiRemoveVote = async (boardId, columnId, cardId) =>
  await connection.signal.invoke('RemoveVote', boardId, columnId, cardId);

const apiUpdateCard = async (boardId, columnId, cardId, text) =>
  await connection.signal.invoke('EditCard', boardId, columnId, cardId, text);

const apiRemoveCard = async (boardId, columnId, cardId) =>
  await connection.signal.invoke('RemoveCard', boardId, columnId, cardId);

const apiSetCardOrder = async (boardId, columnId, cardId, order) =>
  await connection.signal.invoke(
    'SetCardOrder',
    boardId,
    columnId,
    cardId,
    order
  );

const apiIndicateCardEditing = async (boardId, columnId, cardId) => {
  await connection.signal.invoke(
    'IndicateEditing',
    boardId,
    columnId,
    cardId,
    getUserName()
  );
};

const apiStopCardEditing = async (boardId, columnId, cardId) => {
  await connection.signal.invoke(
    'StopEditing',
    boardId,
    columnId,
    cardId,
    getUserName()
  );
};

const apiRenameColumn = async (boardId, columnId, newName) =>
  await connection.signal.invoke('RenameColumn', boardId, columnId, newName);

const apiSetColumnOrder = async (boardId, columnId, order) =>
  await connection.signal.invoke('SetColumnOrder', boardId, columnId, order);

const apiOnChangeCardColumn = async (
  boardId,
  moveColumnId,
  moveCardId,
  directionColumnId,
  order = -1
) => {
  await connection.signal.invoke(
    'ChangeCardColumn',
    boardId,
    moveColumnId,
    moveCardId,
    directionColumnId,
    order
  );
};

const apiMergeCards = async (boardId, columnId, cardId, destinationId) => {
  await connection.signal.invoke(
    'MergeCards',
    boardId,
    columnId,
    cardId,
    destinationId
  );
};

const apiChangeUserName = async (boardId, username, oldUserId = null) => {
  await connection.signal.invoke(
    'ChangeUsername',
    boardId,
    username,
    oldUserId
  );
};

export const handlers = {
  cardAdd: curry(apiAddCard),
  cardRemove: curry(apiRemoveCard),
  cardUpdate: curry(apiUpdateCard),
  cardSetOrder: curry(apiSetCardOrder),
  cardStartEditing: curry(apiIndicateCardEditing),
  cardStopEditing: curry(apiStopCardEditing),
  voteAdd: curry(apiAddVote),
  voteRemove: curry(apiRemoveVote),
  columnRename: curry(apiRenameColumn),
  mergeCards: curry(apiMergeCards),
  cardChangeColumn: curry(apiOnChangeCardColumn),
  setColumnOrder: curry(apiSetColumnOrder),
  changeUsername: apiChangeUserName,
};
