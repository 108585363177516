import React from 'react';
import { values } from 'lodash/fp';
import { connect } from 'react-redux';
import { connection, saveBoard } from 'root/utils';
import { FourOhFour } from 'components/ErrorPages';
import { toast } from 'react-toastify';
import Column from 'components/column';
import BoardHeader from 'components/board/BoardHeader';
import VoteNotification from 'components/board/VoteNotification';
import WebSocketsHandlers from 'components/board/WebSocketsHandlers';
import DragDropContainer from 'components/board/DragDropContainer';
import * as visibleAction from 'root/store/actions/visible';

import 'components/board/board.scss';

class Board extends React.Component {
  state = {
    isSavingBoard: false,
    isLoaded: false,
    isBoardOverflown: false,
    boardScrollLeft: 0,
    boardOffset: 0,
  };

  componentDidMount() {
    const boardId = this.props.match.params.id;
    connection.joinBoard(boardId).then(() => this.setState({ isLoaded: true }));
    this.props.setToEditMode();
    window.addEventListener('resize', this.handleWindowSizeChange);
    if (this.elementRef) {
      this.elementRef.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.boardId) {
      connection.signal.invoke('Leave', this.props.boardId);
    }
    window.removeEventListener('resize', this.handleWindowSizeChange);
    if (this.elementRef) {
      this.elementRef.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps) {
    const { columns } = this.props;
    const currentColumnLenght = Object.keys(columns).length;
    const prevColumnLenght = Object.keys(prevProps.columns).length;
    if (currentColumnLenght !== prevColumnLenght) {
      if (this.elementRef) this.checkOverflow(this.elementRef);
    }
  }

  apiAddColumn = (columnName) => {
    const { boardId } = this.props;
    connection.signal.invoke('AddColumn', boardId, columnName);
  };

  apiRemoveColumn = (columnId) => {
    const { boardId } = this.props;
    connection.signal.invoke('RemoveColumn', boardId, columnId);
  };

  apiVotesVisibility = () => {
    const { boardId, votesVisibility } = this.props;
    connection.signal.invoke('SetVoteVisibility', boardId, !votesVisibility);
  };

  apiSetAvailableVotes = (newVotesPerUser) => {
    const { boardId } = this.props;
    connection.signal.invoke('SetAvailableVotes', boardId, newVotesPerUser);
  };

  // TODO przemyśleć, czy to jest odpowiednie miejsce dla apiGetUpdate
  apiGetColumns = async () => {
    const { boardId } = this.props;
    connection.signal.invoke('GetColumns', boardId);
  };

  apiSaveBoard = async () => {
    this.setState({ isSavingBoard: true });
    const { boardId } = this.props;

    saveBoard(boardId)
      .then(() => {
        toast.info('Board saved');
        this.setState({ isSavingBoard: false });
      })
      .catch((err) => {
        console.error('Something went wrong when saving board: ', err);
      });
  };

  handleAddNewColumn = () => this.apiAddColumn('new column');

  getVotingProgress = (columns, votesPerUser) => {
    const votes = values(columns).reduce(
      (acc, curr) => [...acc, ...curr.cards.flatMap((card) => card.votes)],
      []
    );

    const distinct = votes.filter((v, i, self) => self.indexOf(v) === i);
    if (!distinct.length) return { votes: 0, max: 0 };
    return { votes: votes.length, max: distinct.length * votesPerUser };
  };

  handleScroll = () => {
    this.setState({
      boardScrollLeft: this.elementRef.scrollLeft,
    });
  };

  handleWindowSizeChange = () => {
    if (this.elementRef) this.checkOverflow(this.elementRef);
  };

  checkOverflow = (el) => {
    const isOverflown = el.scrollWidth > el.clientWidth;
    this.setState({
      isBoardOverflown: isOverflown,
      boardOffset: el.scrollWidth - el.clientWidth,
    });
  };

  handleScrollClick = (direction) => {
    const { boardScrollLeft } = this.state;
    const scrollValue = 280;

    if (this.elementRef) {
      switch (direction) {
        case 'left': {
          this.elementRef.scrollLeft = boardScrollLeft - scrollValue;
          break;
        }

        case 'right': {
          this.elementRef.scrollLeft = boardScrollLeft + scrollValue;
          break;
        }

        default:
          break;
      }
    }
  };

  render() {
    const {
      boardId,
      columns,
      votesPerUser,
      usedVotesPerActiveUser,
    } = this.props;
    const {
      isLoaded,
      isSavingBoard,
      isBoardOverflown,
      boardScrollLeft,
      boardOffset,
    } = this.state;

    const votingProgress = this.getVotingProgress(columns, votesPerUser);

    if (isLoaded && !boardId) {
      return (
        <FourOhFour text="Board does not exist. Consider creating a new one." />
      );
    }

    return (
      <div className="board-container">
        <WebSocketsHandlers />
        {votesPerUser && usedVotesPerActiveUser !== null && (
          <VoteNotification />
        )}
        <BoardHeader
          apiGetColumns={this.apiGetColumns}
          handleAddNewColumn={this.handleAddNewColumn}
          handleChangeVoteVisibility={this.apiVotesVisibility}
          apiSetAvailableVotes={this.apiSetAvailableVotes}
          isSavingBoard={isSavingBoard}
          apiSaveBoard={this.apiSaveBoard}
        />
        <div className="voting-progress">
          <progress
            className="progress is-warning"
            value={votingProgress.votes}
            max={votingProgress.max}
          />
          <span>
            {votingProgress.votes} / {votingProgress.max} votes | {votesPerUser}{' '}
            per user
          </span>
        </div>
        <DragDropContainer boardId={boardId}>
          <div className="board__inner">
            <div
              className="columns columns-container"
              ref={(el) => {
                this.elementRef = el;
              }}
            >
              {values(columns)
                .sort((a, b) => a.order - b.order)
                .map((column, index) => (
                  <Column
                    key={column.id}
                    columnId={column.id}
                    color={column.color}
                    name={column.name}
                    handleRemove={this.apiRemoveColumn}
                    usersCurrentlyAddingCards={column.usersCurrentlyAddingCards}
                    leftIndex={column.order > 0 ? column.order - 1 : null}
                    rightIndex={
                      column.order < Object.keys(columns).length - 1
                        ? column.order + 1
                        : null
                    }
                  />
                ))}
              {isBoardOverflown && (
                <>
                  {boardScrollLeft > 0 && (
                    <button
                      onClick={() => this.handleScrollClick('left')}
                      className="columns__scroll-button columns__scroll-button--left hide-mobile"
                      type="button"
                    >
                      <span />
                      <span />
                      <span />
                    </button>
                  )}
                  {boardScrollLeft < boardOffset && (
                    <button
                      onClick={() => this.handleScrollClick('right')}
                      className="columns__scroll-button columns__scroll-button--right hide-mobile"
                      type="button"
                    >
                      <span />
                      <span />
                      <span />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </DragDropContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.auth.isUserLoggedIn,
  columns: state.board.columns,
  boardId: state.board.boardId,
  votesVisibility: state.board.votesVisibility,
  votesPerUser: state.board.votesPerUser,
  usedVotesPerActiveUser: state.board.usedVotesPerActiveUser,
});

const mapDispatchToProps = (dispatch) => ({
  setToEditMode: () => dispatch(visibleAction.setToEditMode()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Board);
