export const LOGIN_USER_BEGIN = 'LOGIN_USER_BEGIN';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';

export const BOARD_ON_LOAD = 'BOARD_ON_LOAD';
export const USER_REJOINED = 'USER_REJOINED';

export const ADD_COLUMN = 'ADD_COLUMN';
export const REMOVE_COLUMN = 'REMOVE_COLUMN';
export const RENAME_COLUMN = 'RENAME_COLUMN';
export const SORT_CARD_IN_COLUMN = 'SORT_CARD_IN_COLUMN';
export const SET_COLUMN_ORDER = 'SET_COLUMN_ORDER';

export const ADD_CARD = 'ADD_CARD';
export const REMOVE_CARD = 'REMOVE_CARD';
export const EDIT_CARD = 'EDIT_CARD';
export const INDICATE_EDITING_CARD = 'INDICATE_EDITING_CARD';
export const STOP_EDITING_CARD = 'STOP_EDITING_CARD';
export const REORDER_CARDS_IN_COLUMN = 'REORDER_CARDS_IN_COLUMN';
export const CHANGE_CARD_COLUMN = 'CHANGE_CARD_COLUMN';
export const MOVE_CARD_TO_ANOTHER_COLUMN = 'MOVE_CARD_TO_ANOTHER_COLUMN';
export const CHANGE_CARD_ORDER = 'CHANGE_CARD_ORDER';

export const ADD_VOTE = 'ADD_VOTE';
export const REMOVE_VOTE = 'REMOVE_VOTE';

export const TOGGLE_VOTE_VISIBILITY = 'TOGGLE_VOTE_VISIBILITY';
export const SET_MAX_VOTES_PER_USER = 'SET_MAX_VOTES_PER_USER';

export const ON_START_MOVING_CARD = 'ON_START_MOVING_CARD';
export const MOVE_CARD_SUCCESS = 'MOVE_CARD_SUCCESS';

export const CHOOSE_CARD_ID_FOR_MERGE = 'CHOOSE_CARD_ID_FOR_MERGE';
export const MERGE_CARD_SUCCESS = 'MERGE_CARD_SUCCESS';

export const SET_READ_ONLY_MODE = 'SET_READ_ONLY_MODE';
export const SHOW_VOTES = 'SHOW_VOTES';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';

export const UPDATE_NAMES_REGISTRY = 'UPDATE_NAMES_REGISTRY';
