const localStorageTokenName = 'Boldaretro_user_name';
export const localStorageUserId = 'boldaretroUserId-';

export const getUserNamesFromSet = (setOfUserNames) =>
  setOfUserNames && setOfUserNames.size > 0
    ? [...setOfUserNames].map((userName) => userName || 'Anonymous').join(', ')
    : '';

export const getUserId = (boardId) => {
  const userId = localStorage.getItem(`${localStorageUserId}${boardId}`);
  return userId ? JSON.parse(userId).id : null;
};

export const getUserIdExpiresDate = (boardId) => {
  const userId = localStorage.getItem(`${localStorageUserId}${boardId}`);
  return userId ? JSON.parse(userId).expires : null;
};

export const setUser = (boardId, userId, nameRegistry) => {
  const date = new Date().getTime();
  localStorage.setItem(
    `${localStorageUserId}${boardId}`,
    JSON.stringify({
      id: userId,
      name: nameRegistry[userId],
      expires: date + 60 * 60 * 1000 * 24,
    })
  );
};

export const getUserName = () => {
  const boardId = localStorage.getItem('boardId');
  const user = localStorage.getItem(`${localStorageUserId}${boardId}`);
  return JSON.parse(user)?.name;
};
