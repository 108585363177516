import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-autosize-textarea';
import Button from 'components/button';
import ReactTooltip from 'react-tooltip';

import ConfirmPopup from 'components/popup';
import 'components/card/card.scss'; // TODO move here
import { connect } from 'react-redux';
import * as boardAction from 'root/store/actions/board';
import { handlers } from 'root/utils';
import { throttle, debounce } from 'lodash';
import { getUserNamesFromSet } from 'root/userNameHelpers';
import TypingDots from 'components/typingDots';

class CardView extends React.PureComponent {
  wrapper = React.createRef();

  textAreaRef = React.createRef();

  static defaultProps = {
    defaultText: '',
  };

  state = {
    text: '',
    editMode: false,
    defaultText: '',
    isPopupShown: false,
  };

  componentDidMount() {
    const { defaultText, newCard } = this.props;
    this.setState({ defaultText, text: defaultText });
    if (newCard) {
      this.textAreaRef.current.focus();
    }

    document.addEventListener('mousedown', this._clickOutside);
  }

  componentDidUpdate() {
    const { defaultText } = this.props;
    if (defaultText !== this.state.defaultText)
      this.setState({ defaultText, text: defaultText });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._clickOutside);
  }

  handleChange = (event) => {
    this.indicateCardEditing();
    this.stoppedEditing();
    this.setState({
      text: event.target.value,
    });
  };

  indicateCardEditing = throttle(() => {
    const { editMode } = this.state;
    const { boardId, columnId, cardId, newCard } = this.props;
    if (editMode || newCard) {
      handlers.cardStartEditing(boardId, columnId, cardId);
    }
  }, 1000);

  stoppedEditing = debounce(() => {
    const { boardId, columnId, cardId } = this.props;
    handlers.cardStopEditing(boardId, columnId, cardId);
  }, 5000);

  _submit = async () => {
    const { newCard, onSubmit, onCancel } = this.props;
    await onSubmit(this.state.text.trim());
    newCard ? onCancel() : this.changeEditMode(false);
  };

  _clickOutside = (e) => {
    const { newCard } = this.props;
    if (!this.wrapper.current.contains(e.target)) {
      if (this.ableToSave) {
        // alert('You clicked outside of me!')
        // TODO add propmpt
        e.preventDefault();
        // this.textarea.current.focusTextArea()
      } else {
        if (newCard) this.props.onCancel();
        this.changeEditMode(false);
      }
    }
  };

  changeEditMode = (bool) => this.setState({ editMode: bool });

  get ableToSave() {
    const { editMode, text, defaultText } = this.state;
    return editMode && text !== defaultText;
  }

  _setHeight = () => {
    this.textarea.style.height = 'auto';
    this.textarea.style.height = `${this.textarea.scrollHeight}px`;
  };

  handleMoveClick = () =>
    this.props.onStartMovingCard(this.props.cardId, this.props.columnId);

  getMoveButtonText = () =>
    this.props.moveCardId === this.props.cardId ? 'Moving' : 'Move';

  _handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      this._submit();
      this.changeEditMode(false);
      this.textAreaRef.current.blur();
    }
  };

  render() {
    const {
      colorClass,
      onCancel,
      buttonText,
      newCard,
      readOnly,
      countColumns,
      authors,
      cardId,
      indexInList,
      editedBy,
    } = this.props;
    const { editMode, text, isPopupShown } = this.state;
    const { nameRegistry } = this.props;

    const authorsString = authors
      ? authors.map((id) => nameRegistry[id] || 'Anonymous').join(', ')
      : '';

    const editedByString = getUserNamesFromSet(editedBy);

    return (
      <Draggable
        draggableId={cardId || `new_card`}
        index={indexInList !== undefined ? indexInList : -1}
        isDragDisabled={isPopupShown || editMode || readOnly || newCard}
      >
        {(provided) => (
          <div
            className={`card retro-card retro-card--new${
              editedByString ? ' retro-card--edited' : ''
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <article className="message " ref={this.wrapper}>
              <div className={`message-header ${colorClass}`}>
                <div className="card__container">
                  <TextareaAutosize
                    value={text}
                    disabled={readOnly}
                    rows={1}
                    maxRows={10}
                    ref={this.textAreaRef}
                    onFocus={() => this.changeEditMode(true)}
                    onChange={this.handleChange}
                    onKeyDown={this._handleKeyDown}
                    className={`card__content ${(newCard || editMode) &&
                      'card__edit'}`}
                    placeholder="Enter your thoughts here..."
                  />
                  {!newCard && (
                    <>
                      {editedByString && <TypingDots />}
                      <a
                        className="card__tooltip"
                        data-tip={`${
                          authorsString.match(', ') ? 'Authors:' : 'Author:'
                        } ${authorsString}${
                          editedByString ? `. Edited by ${editedByString}` : ''
                        }`}
                      >
                        <i className="fas fa-user" />
                      </a>
                      <ReactTooltip place="top" type="dark" effect="solid" />
                    </>
                  )}
                </div>
                <div className="card__actions-container">
                  {this.ableToSave && (
                    <Button
                      className="button card__actions--button button--small button--white"
                      onClick={this._submit}
                    >
                      {buttonText}
                    </Button>
                  )}
                  {!editMode && (
                    <>
                      {!readOnly && !newCard && countColumns > 1 && (
                        <Button
                          className="button card__actions--button button--small button--white"
                          onClick={this.handleMoveClick}
                        >
                          {this.getMoveButtonText()}
                        </Button>
                      )}
                      {this.props.children}
                    </>
                  )}
                  {!readOnly && (
                    <ConfirmPopup
                      onApprove={onCancel}
                      text="Are you sure you want to delete this card?"
                      trigger={
                        <div className="card__action card__action--remove">
                          <i className="fas fa-trash-alt card__action--icon" />
                        </div>
                      }
                      onOpen={() => {
                        this.setState({ isPopupShown: true });
                      }}
                      onClose={() => {
                        this.setState({ isPopupShown: false });
                      }}
                    />
                  )}
                </div>
              </div>
            </article>
          </div>
        )}
      </Draggable>
    );
  }
}

const mapStateToProps = (state) => ({
  moveCardId: state.board.moveCardId,
  readOnly: state.visible.readOnly,
  countColumns: Object.keys(state.board.columns).length,
  nameRegistry: state.board.nameRegistry,
});

const mapDispatchToProps = (dispatch) => ({
  onStartMovingCard: (cardId, columnId) =>
    dispatch(boardAction.onStartMovingCard(cardId, columnId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardView);
