import React from 'react';
import { LIMIT_VOTES_PER_USER, MOBILE_VIEW } from 'root/constants';

const InputVotes = ({ maxVotes, setMaxVotes }) => {
  const handleSetMaxVotes = ({ target: { value } }) => {
    if (!value) {
      setMaxVotes(value);
    }
    const votes =
      value >= 0 && value <= LIMIT_VOTES_PER_USER ? parseInt(value) : maxVotes;
    if (!Number.isNaN(votes)) {
      setMaxVotes(votes);
    }
  };

  return (
    <input
      className="input entry-form__input"
      type="text"
      pattern="^[0-9]*"
      placeholder={
        MOBILE_VIEW
          ? `Max votes per user`
          : `Max votes per user (limit ${LIMIT_VOTES_PER_USER})`
      }
      value={maxVotes}
      onChange={handleSetMaxVotes}
    />
  );
};

export default InputVotes;
