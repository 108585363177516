import React, { Component } from 'react';
import { loggedIn } from 'root/jwt-helpers';
import SpinnerPage from 'components/spinner/SpinnerPage';

export default function withAuth(AuthComponent) {
  class AuthWrapped extends Component {
    state = {
      waitingForAuthorizationCheck: true,
    };

    componentDidMount() {
      if (!loggedIn()) {
        this.props.history.push({
          pathname: '/',
        });
      }

      this.setState({
        waitingForAuthorizationCheck: false,
      });
    }

    render() {
      if (this.state.waitingForAuthorizationCheck) {
        return <SpinnerPage />;
      }

      return <AuthComponent {...this.props} />;
    }
  }

  return AuthWrapped;
}
