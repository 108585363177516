import React from 'react';
import './typingDots.scss';

const TypingDots = () => (
  <div className="typing-dots">
    <span className="typing-dots__dot"></span>
    <span className="typing-dots__dot"></span>
    <span className="typing-dots__dot"></span>
  </div>
);
export default TypingDots;
