import React, { useState } from 'react';
import Button from 'components/button';
import Header, { HeaderLight } from 'components/header';
import VotesEdit from 'components/votesEdit';
import ModalNameEdit from 'components/modalNameEdit';
import { connect } from 'react-redux';
import { useWindowWidth } from 'root/customHooks';

import LoginByGoogle from 'components/loginByGoogle';
import { isFullAppVersion } from 'root/utils';

const BoardHeader = React.memo((props) => {
  const {
    votesPerUser,
    name,
    apiGetColumns,
    handleAddNewColumn,
    handleChangeVoteVisibility,
    apiSetAvailableVotes,
    apiSaveBoard,
    isSavingBoard,
    sortType,
    isUserLoggedIn,
    readOnly,
    votesVisibility,
  } = props;
  const windowSize = useWindowWidth();

  const [optionsVisible, setOptionsVisible] = useState(
    !windowSize.isMobileView
  );

  const buttonsClass = optionsVisible ? '' : 'buttons--hidden';
  const iconClass = optionsVisible ? 'up' : 'down';

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  const renderButtons = () => (
    <div
      className={`buttons buttons--main ${
        windowSize.isMobileView ? buttonsClass : ''
      }`}
    >
      {votesVisibility && (
        <Button className="button button--white" onClick={apiGetColumns}>
          <i className="fas fa-sort card__action--icon" />{' '}
          {sortType === 'order' ? 'Sort by votes' : 'Sort by order'}
        </Button>
      )}
      <Button className="button button--white" onClick={handleAddNewColumn}>
        <i className="fas fa-plus-square card__action--icon" /> Add new column
      </Button>
      <Button
        className="button button--white"
        onClick={handleChangeVoteVisibility}
      >
        {votesVisibility ? (
          <>
            <i className="fas fa-eye-slash card__action--icon" /> Hide votes
          </>
        ) : (
          <>
            <i className="fas fa-eye card__action--icon" /> Show votes
          </>
        )}
      </Button>

      <VotesEdit
        votesPerUser={votesPerUser}
        handleSetMaxVotesNumber={apiSetAvailableVotes}
      />
      <ModalNameEdit />
      {isFullAppVersion() && (
        <>
          {isUserLoggedIn ? (
            <Button
              className="button button--white"
              onClick={apiSaveBoard}
              disabled={isSavingBoard}
            >
              <i className="fas fa-save card__action--icon" />{' '}
              {`Sav${isSavingBoard ? 'ing' : 'e'} Board`}
            </Button>
          ) : (
            <LoginByGoogle
              buttonClassNames="button button--white"
              loggedOutButtonText="Login to save"
            />
          )}
        </>
      )}
    </div>
  );

  const BoardHeaderType = isFullAppVersion() ? (
    <Header toUrl="/history" goBackText="Go to last boards page" title={name} />
  ) : (
    <HeaderLight toUrl={process.env.BOLDARE_PAGE_URL} title={name} />
  );

  return (
    <>
      {BoardHeaderType}
      {!readOnly && (
        <div className="buttons">
          {windowSize.isMobileView && (
            <div className="button--toggle-wrapper">
              <Button onClick={toggleOptions} className="button">
                {optionsVisible ? 'Hide options ' : 'Show options '}
                <i className={`fas fa-angle-${iconClass}`} />
              </Button>
            </div>
          )}
          {renderButtons()}
        </div>
      )}
    </>
  );
});

BoardHeader.displayName = 'BoardHeader';

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.auth.isUserLoggedIn,
  readOnly: state.visible.readOnly,
  sortType: state.board.sortType,
  votesPerUser: state.board.votesPerUser,
  name: state.board.name,
  votesVisibility: state.board.votesVisibility,
});

export default connect(
  mapStateToProps,
  null
)(BoardHeader);
