import React from 'react';
import { handlers } from 'root/utils';
import { connect } from 'react-redux';
import Dots from 'components/cardVote/dots';
import Button from 'components/button';

const CardVotes = ({
  cardId,
  voteCount,
  activeUserVotes,
  boardId,
  columnId,
  votesVisibility,
  votesPerUser,
  usedVotesPerActiveUser,
  readOnly,
}) => {
  const isAllVoteUsed = !(votesPerUser > usedVotesPerActiveUser);
  return (
    <div className="votes">
      {!readOnly && (
        <div className="votes__action-wrapper">
          <Dots activeUserVotes={activeUserVotes} />
        </div>
      )}

      {!readOnly && activeUserVotes.length > 0 && (
        <div
          className="card__action card__action--cancel"
          onClick={() => {
            handlers.voteRemove(boardId, columnId, cardId);
          }}
        >
          <i className="fas fa-times card__action--icon" />
        </div>
      )}

      <Button
        className="card__action button--simple"
        disabled={readOnly || isAllVoteUsed}
        onClick={() => {
          handlers.voteAdd(boardId, columnId, cardId);
        }}
      >
        <i className="fas fa-thumbs-up " />
        {votesVisibility && (
          <div className="card__action--number">{voteCount}</div>
        )}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  votesVisibility: state.board.votesVisibility,
  votesPerUser: state.board.votesPerUser,
  usedVotesPerActiveUser: state.board.usedVotesPerActiveUser,
  readOnly: state.visible.readOnly,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardVotes);
