import React, { useState } from 'react';
import CardView from 'components/cardView';
import { handlers } from 'root/utils';

import 'components/newCardAdd/newCardAdd.scss';

const onNewCardSubmit = (boardId, columnId, text) => {
  handlers.cardAdd(boardId, columnId, text);
  handlers.cardStopEditing(boardId, columnId, null);
};

const NewCardAdd = ({ colorClass, order, boardId, columnId }) => {
  const [isActive, setActivity] = useState(false);
  const addNewCard = () => setActivity(true);
  const orderClass = order ? `new-card-add__button--${order}` : '';

  return (
    <div className="new-card-add">
      {order === 'top' && (
        <a
          className={`button is-light new-card-add__button ${orderClass}`}
          onClick={addNewCard}
        >
          +
        </a>
      )}
      {isActive && (
        <CardView
          boardId={boardId}
          columnId={columnId}
          colorClass={colorClass}
          onSubmit={(text) => onNewCardSubmit(boardId, columnId, text)}
          buttonText="Add"
          onCancel={() => setActivity(false)}
          newCard
        />
      )}
      {order !== 'top' && (
        <a
          className={`button is-light new-card-add__button ${orderClass}`}
          onClick={addNewCard}
        >
          +
        </a>
      )}
    </div>
  );
};

export default NewCardAdd;
