import React, { Component } from 'react';
import { connection } from 'root/utils';
import { connect } from 'react-redux';
import { getUserId, setUser } from 'root/userNameHelpers';
import { toast } from 'react-toastify';
import { errorDictionary } from 'root/constants';

import * as boardAction from 'root/store/actions/board';

class WebSocketsHandlers extends Component {
  componentDidMount() {
    connection.on('boardJoined', this._boardJoined);
    connection.on('userRejoined', this._userRejoined);

    connection.on('cardAdded', this._cardAdded);
    connection.on('cardRemoved', this._cardRemoved);
    connection.on('cardsMerged', this._cardsMerged);
    connection.on('cardColumnChanged', this._cardColumnChanged);
    connection.on('cardEdited', this._cardUpdated);
    connection.on('cardOrderChanged', this._cardOrderChanged);
    connection.on('indicateEditing', this._indicateCardEditing);
    connection.on('stoppedEditing', this._stoppedCardEditing);
    connection.on('columnsListed', this._columnsListed);

    connection.on('voteAdded', this._addVote);
    connection.on('voteRemoved', this._removeVote);

    connection.on('columnAdded', this._columnAdded);
    connection.on('columnRemoved', this._columnRemoved);
    connection.on('columnOrderChanged', this._columnOrderChanged);
    connection.on('columnRenamed', this._columnRenamed);

    connection.on('voteVisibilityChanged', this._votesVisibilityChanged);
    connection.on('availableVotesChanged', this._availableVotesChanged);

    connection.on('usernameChanged', this._usernameChanged);

    connection.on('error', this._error);
  }

  componentWillUnmount() {
    connection.off('boardJoined', this._boardJoined);
    connection.off('userRejoined', this._userRejoined);

    connection.off('cardAdded', this._cardAdded);
    connection.off('cardRemoved', this._cardRemoved);
    connection.off('cardsMerged', this._cardsMerged);
    connection.off('cardColumnChanged', this._cardColumnChanged);
    connection.off('cardEdited', this._cardUpdated);
    connection.off('cardOrderChanged', this._cardOrderChanged);
    connection.off('indicateEditing', this._indicateCardEditing);
    connection.off('stoppedEditing', this._stoppedCardEditing);
    connection.off('columnsListed', this._columnsListed);

    connection.off('voteAdded', this._addVote);
    connection.off('voteRemoved', this._removeVote);

    connection.off('columnAdded', this._columnAdded);
    connection.off('columnRemoved', this._columnRemoved);
    connection.off('columnOrderChanged', this._columnOrderChanged);
    connection.off('columnRenamed', this._columnRenamed);

    connection.off('voteVisibilityChanged', this._votesVisibilityChanged);
    connection.off('availableVotesChanged', this._availableVotesChanged);

    connection.off('error', this._error);
  }

  _boardJoined = ({ board, userId }) => {
    localStorage.setItem('boardId', board.id);
    setUser(board.id, userId, board.nameRegistry);
    this.props.handleBoardOnLoad(board);
  };

  _userRejoined = (rejoinedData) => {
    this.props.userRejoined(rejoinedData);
  };

  _columnRenamed = ({ columnId, newName }) => {
    this.props.renameColumn(columnId, newName);
  };

  _cardUpdated = (editedCardData) => {
    this.props.editCard(editedCardData);
  };

  _columnsListed = (columns) => this.props.sortCardInColumn(columns);

  _cardsMerged = ({ columnId, removedIds, newCard }) => {
    removedIds.forEach((cardId) => {
      this._cardRemoved({ columnId, cardId }, false);
    });
    this._cardAdded({ columnId, card: newCard });
    this.props.mergeCardSuccess();
  };

  _cardAdded = (data) => {
    const { handleAddCard } = this.props;
    handleAddCard(data);
  };

  _addVote = ({ cardId, userId, columnId }) => {
    const { addVote } = this.props;
    addVote(columnId, cardId, userId);
  };

  _removeVote = ({ cardId, userId, columnId }) => {
    const { removeVote } = this.props;
    removeVote(columnId, cardId, userId);
  };

  _cardRemoved = (data, updateVotes = true) => {
    const { handleRemoveCard } = this.props;
    handleRemoveCard(data, updateVotes);
  };

  _indicateCardEditing = ({ columnId, cardId, username }) => {
    const { indicateEditingCard } = this.props;
    indicateEditingCard(columnId, cardId, username);
  };

  _stoppedCardEditing = ({ columnId, cardId, username }) => {
    const { stoppedEditingCard } = this.props;
    stoppedEditingCard(columnId, cardId, username);
  };

  _cardColumnChanged = ({
    card,
    targetColumnId,
    columnId,
    sourceCardOrder,
    cardOrder,
  }) => {
    const { columns } = this.props;
    if (!columns[targetColumnId].cards.find((c) => c.id === card.id)) {
      this._cardRemoved({ columnId, cardId: card.id }, false);
      this._cardAdded({ columnId: targetColumnId, card });
      this._cardOrderChanged({ columnId, cardOrder: sourceCardOrder });
      this._cardOrderChanged({ columnId: targetColumnId, cardOrder });
    }
  };

  _columnAdded = (column) => {
    this.props.handleAddColumn(column);
  };

  _columnRemoved = (columnId) => {
    this.props.handleRemoveColumn(columnId);
  };

  _cardOrderChanged = ({ columnId, cardOrder }) => {
    const { reorderCardsInColumn } = this.props;
    reorderCardsInColumn(columnId, cardOrder);
  };

  _votesVisibilityChanged = () => {
    this.props.toggleVoteVisibility();
  };

  _availableVotesChanged = (votesPerUser) => {
    this.props.setMaxVotesPerUser(votesPerUser);
  };

  _columnOrderChanged = (itemOrder) => {
    this.props.handleColumnOrderChanged(itemOrder);
  };

  _usernameChanged = (nameRegistry) => {
    const boardId = localStorage.getItem('boardId');
    const userId = getUserId(boardId);
    if (boardId && userId && nameRegistry[userId]) {
      setUser(boardId, userId, nameRegistry);
    }

    this.props.usernameChanged(nameRegistry);
  };

  _error = (error) => {
    const message = errorDictionary[error] || 'Something went wrong';
    toast.error(message);
    console.error(error);
  };

  render() {
    return <div />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleBoardOnLoad: (boardData) =>
    dispatch(boardAction.boardOnLoad(boardData)),
  userRejoined: (rejoinedData) =>
    dispatch(boardAction.userRejoined(rejoinedData)),
  moveCardSuccess: () => dispatch(boardAction.moveCardSuccess()),
  mergeCardSuccess: () => dispatch(boardAction.mergeCardSuccess()),
  sortCardInColumn: (columns) =>
    dispatch(boardAction.sortCardInColumn(columns)),
  handleAddCard: (card) => dispatch(boardAction.addCard(card)),
  editCard: (editedCardData) => dispatch(boardAction.editCard(editedCardData)),
  reorderCardsInColumn: (columnId, cardOrderData) =>
    dispatch(boardAction.reorderCardsInColumn(columnId, cardOrderData)),
  handleRemoveCard: (removeCardData, updateVotes) =>
    dispatch(boardAction.removeCard(removeCardData, updateVotes)),
  indicateEditingCard: (columnId, cardId, userName) =>
    dispatch(boardAction.indicateEditingCard(columnId, cardId, userName)),
  stoppedEditingCard: (columnId, cardId, userName) =>
    dispatch(boardAction.stopEditingCard(columnId, cardId, userName)),
  handleAddColumn: (column) => dispatch(boardAction.addColumn(column)),
  handleRemoveColumn: (columnId) =>
    dispatch(boardAction.removeColumn(columnId)),
  handleColumnOrderChanged: (itemOrder) =>
    dispatch(boardAction.setColumnOrder(itemOrder)),
  addVote: (columnId, cardId, userId) =>
    dispatch(boardAction.addVote(columnId, cardId, userId)),
  removeVote: (columnId, cardId, userId) =>
    dispatch(boardAction.removeVote(columnId, cardId, userId)),
  toggleVoteVisibility: () => dispatch(boardAction.toggleVoteVisibility()),
  setMaxVotesPerUser: (votesPerUser) =>
    dispatch(boardAction.setMaxVotesPerUser(votesPerUser)),
  renameColumn: (columnId, name) =>
    dispatch(boardAction.renameColumn(columnId, name)),
  usernameChanged: (nameRegistry) =>
    dispatch(boardAction.updateNamesRegistry(nameRegistry)),
});

const mapStateToProps = (state) => ({
  columns: state.board.columns,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebSocketsHandlers);
