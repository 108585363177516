import React from 'react';
import './addingCardIndicator.scss';
import TypingDots from 'components/typingDots';
import { getUserNamesFromSet } from 'root/userNameHelpers';

const AddingCardIndicator = ({ usersCurrentlyAddingCards }) => (
  <section className="card adding-card-indicator">
    <div className="adding-card-indicator__message">
      <p>
        {getUserNamesFromSet(usersCurrentlyAddingCards)}{' '}
        {usersCurrentlyAddingCards.size === 1
          ? 'is adding a card'
          : 'are adding cards'}
      </p>
      <TypingDots />
    </div>
    <i className="fas fa-pencil-alt adding-card-indicator__icon" />
  </section>
);

export default AddingCardIndicator;
