import { combineReducers } from 'redux';
import authReducer from './auth';
import boardReducer from './board';
import visibleReducer from './visible';

export default combineReducers({
  auth: authReducer,
  visible: visibleReducer,
  board: boardReducer,
});
