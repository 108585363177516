import * as boardAction from 'root/store/actions/board';
import * as types from './types';

export const setToReadOnlyMode = () => ({
  type: types.SET_READ_ONLY_MODE,
});

export const setToEditMode = () => ({
  type: types.SET_EDIT_MODE,
});

export const handleSetToReadOnlyMode = () => (dispatch) => {
  dispatch(setToReadOnlyMode());
  dispatch(boardAction.showVotes());
};
