import React, { Component } from 'react';
import { apiAuthorizedCall } from 'root/utils';
import isEmpty from 'lodash/isEmpty';
import withAuth from 'components/withAuth';
import Header from 'components/header';
import Directories from './Directories';
import AddFolder from './AddFolder';
import Boards from './Boards';
import './archive.scss';

class Archive extends Component {
  state = {
    directories: [],
    boards: [],
    error: null,
    directoryName: '',
    parentId: null,
    activeDirectoryId: '',
    activeRenameDirectoryId: '',
    activeBoardId: '',
  };

  componentDidMount() {
    const parentId = isEmpty(this.props.match.params)
      ? null
      : this.props.match.params.id;

    this.setState({ parentId }, this.getDirectories);
  }

  componentDidUpdate() {
    const parentId = isEmpty(this.props.match.params)
      ? null
      : this.props.match.params.id;
    if (parentId !== this.state.parentId) {
      this.setState({ parentId }, this.getDirectories);
    }
  }

  getDirectories = async () => {
    const { parentId } = this.state;
    if (parentId) {
      try {
        const response = await apiAuthorizedCall(
          `/api/archive/getDirectory?directoryId=${parentId}`
        );
        const folder = await response.json();
        this.setState({
          directories: folder.subdirectories,
          boards: folder.boards,
          directoryName: folder.name,
          error: null,
        });
      } catch (error) {
        this.setState({ error });
      }
    } else {
      try {
        const response = await Promise.all([
          apiAuthorizedCall('/api/archive/getDirectories'),
          apiAuthorizedCall('/api/archive/getOrphanedBoards'),
        ]);

        const directories = await response[0].json();
        const boards = await response[1].json();
        this.setState({ directories, boards, error: null, directoryName: '' });
      } catch (error) {
        this.setState({ error, directoryName: '' });
      }
    }
  };

  removeDirectoryFromList = (activeDirectoryId) => {
    const { directories } = this.state;
    const filteredDirectories = directories.filter(
      (item) => item.id !== activeDirectoryId
    );
    this.setState({ directories: filteredDirectories });
  };

  moveDirectoryOrBoard = async (targetId, up = false) => {
    if (!up && !targetId) return;
    const { activeBoardId, activeDirectoryId } = this.state;

    if (activeDirectoryId) {
      const response = await apiAuthorizedCall(
        `/api/archive/moveDirectory?directoryId=${activeDirectoryId}${
          up ? '' : `&targetId=${targetId}`
        }`
      );

      if (response.ok) {
        this.removeDirectoryFromList(activeDirectoryId);
      }
    }

    if (activeBoardId) {
      const response = await apiAuthorizedCall(
        `/api/archive/moveBoardToDirectory?boardId=${activeBoardId}${
          targetId ? `&directoryId=${targetId}` : ''
        }`
      );

      if (response.ok) {
        this.removeBoardFromList(activeBoardId);
      }
    }

    this.setState({
      activeBoardId: false,
      activeDirectoryId: false,
    });
  };

  removeBoardFromList = (activeBoardId) => {
    const { boards } = this.state;
    const filteredBoards = boards.filter((item) => item.id !== activeBoardId);
    this.setState({ boards: filteredBoards });
  };

  handleBoardClick = (id) => {
    const { activeBoardId } = this.state;
    if (activeBoardId === id) {
      return this.setState({
        activeBoardId: '',
        activeDirectoryId: '',
      });
    }
    this.setState({ activeBoardId: id, activeDirectoryId: '' });
  };

  handleDirectoryClick = (id) => {
    const { activeDirectoryId } = this.state;
    if (activeDirectoryId === id) {
      return this.setState({
        activeBoardId: '',
        activeDirectoryId: '',
      });
    }
    this.setState({ activeBoardId: '', activeDirectoryId: id });
  };

  setRenameDirectoryId = (id) => {
    this.setState({ activeRenameDirectoryId: id });
  };

  renameDirectoryFromList = (name, id) => {
    const { directories } = this.state;
    const renamedDirectoriesArray = directories.map((item) => {
      if (item.id === id) {
        return { ...item, name };
      }

      return item;
    });
    this.setState({
      directories: renamedDirectoriesArray,
    });
  };

  render() {
    const {
      boards,
      directories,
      directoryName,
      error,
      activeBoardId,
      activeDirectoryId,
      parentId,
      activeRenameDirectoryId,
    } = this.state;

    if (error) return <div className="archive-page">Something went wrong</div>;

    const archiveTitle = parentId
      ? `Archive ${directoryName} directory`
      : 'Archive root directory';

    const toUrl = parentId ? '/archive' : '/';
    const goBackText = parentId ? 'Go to root directory' : 'Go to main page';

    return (
      <div className="archive-page">
        <Header toUrl={toUrl} goBackText={goBackText} title={archiveTitle} />
        <AddFolder
          handlePressEnter={this.handlePressEnter}
          getDirectories={this.getDirectories}
          parentId={parentId}
        />
        <section className="archive-page__last-boards">
          <div className="archive-page__action-container">
            Directories
            {(activeDirectoryId || activeBoardId) && parentId && (
              <div onClick={() => this.moveDirectoryOrBoard('', true)}>
                <i
                  className="fas fa-caret-square-down card__action--icon"
                  title="Move to parent"
                />
              </div>
            )}
          </div>
          {isEmpty(directories) && <p>There are no available directories</p>}
          {directories.map((directory) => (
            <Directories
              key={directory.id}
              id={directory.id}
              name={directory.name}
              activeBoardId={activeBoardId}
              activeDirectoryId={activeDirectoryId}
              activeRenameDirectoryId={activeRenameDirectoryId}
              handleDirectoryClick={this.handleDirectoryClick}
              moveDirectoryOrBoard={this.moveDirectoryOrBoard}
              removeDirectoryFromList={this.removeDirectoryFromList}
              renameDirectoryFromList={this.renameDirectoryFromList}
              setRenameDirectoryId={this.setRenameDirectoryId}
            />
          ))}
        </section>
        <section className="archive-page__last-boards">
          Boards
          {isEmpty(boards) && <p>There are no available boards</p>}
          {boards.map((board) => (
            <Boards
              key={board.id}
              id={board.id}
              name={board.name}
              activeBoardId={activeBoardId}
              removeBoardFromList={this.removeBoardFromList}
              handleBoardClick={this.handleBoardClick}
            />
          ))}
        </section>
      </div>
    );
  }
}

export default withAuth(Archive);
