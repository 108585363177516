import { useState, useEffect } from 'react';
import { MEDIA_QUERY } from 'root/constants';

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return {
    width,
    isMobileView: width < MEDIA_QUERY.TABLET,
  };
}
