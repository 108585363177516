import React from 'react';
import reactDOM from 'react-dom';
import { SomethingWentWrongPage } from 'components/ErrorPages';
import AppRouter from 'components/appRouter';
import Notification from 'components/notification';
import { SpinnerPage } from 'components/spinner';

import Footer from 'components/footer';
import { connection } from 'root/utils';
import { getToken } from 'root/jwt-helpers';
import { Provider } from 'react-redux';
import store from 'root/store';
import { localStorageUserId } from 'root/userNameHelpers';

import 'root/index.scss';

document.addEventListener('DOMContentLoaded', () => {
  class App extends React.Component {
    state = {
      isConnecting: true,
      connectionFailed: false,
      hasError: false,
    };

    async componentDidMount() {
      try {
        await connection.setConnection();
        this.setState({ isConnecting: false });
        if (store.getState().auth.isUserLoggedIn) {
          connection.signal.invoke('Authenticate', getToken());
        }
        this.removeExpiresUserId();
      } catch (err) {
        console.error('Something went wrong on initialization: ', err);
        this.setState({ isConnecting: false, connectionFailed: true });
      }
    }

    static getDerivedStateFromError(error) {
      console.error('Something went wrong with App: ', error);
      return { hasError: true };
    }

    removeExpiresUserId = () => {
      const date = new Date().getTime();
      const toRemove = [];

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (
          key.indexOf(localStorageUserId) !== -1 &&
          JSON.parse(localStorage.getItem(key)).expires < date
        ) {
          toRemove.push(key);
        }
      }

      for (const key of toRemove) {
        localStorage.removeItem(key);
      }
    };

    render() {
      const { isConnecting, connectionFailed, hasError } = this.state;
      const connectionReady = !isConnecting && !connectionFailed;
      const connectionFaild = !isConnecting && connectionFailed;

      if (connectionFaild) {
        return (
          <>
            <SomethingWentWrongPage />
          </>
        );
      }

      if (hasError) {
        return (
          <>
            <SomethingWentWrongPage text="Please check error in developer console and contact with Team Boldaretro." />
          </>
        );
      }

      return (
        <>
          {connectionReady ? (
            <Provider store={store}>
              <AppRouter />
            </Provider>
          ) : (
            <SpinnerPage />
          )}
          <Notification />
        </>
      );
    }
  }

  reactDOM.render(<App />, document.getElementById('root'));
});
