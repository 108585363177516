import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'components/notification/message.scss';

const Notification = React.memo(() => (
  <ToastContainer autoClose={3000} closeButton={false} />
));

Notification.displayName = 'Notification';

export default Notification;
