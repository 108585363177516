import React from 'react';
import { Spinner } from 'components/spinner';
import 'components/spinner/spinner.scss';

const SpinnerPage = () => (
  <div className="spinner-page">
    <Spinner />
  </div>
);

export default SpinnerPage;
