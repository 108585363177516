import { userLoginByGoogle, userLoggedOut } from 'root/utils';
import { toast } from 'react-toastify';
import * as types from './types';

const LOGIN_ERROR_MESSAGE = 'Error during login';
const LOGIN_OK_MESSAGE = 'Logged in successfully';
const LOGGED_OUT_MESSAGE = 'Logged out';

export const loginUserBegin = () => ({
  type: types.LOGIN_USER_BEGIN,
});

export const loginUserSuccess = () => {
  toast.success(LOGIN_OK_MESSAGE);
  return {
    type: types.LOGIN_USER_SUCCESS,
  };
};

export const loginUserFailure = (response) => {
  if (response.error) {
    toast.error(LOGIN_ERROR_MESSAGE);
  }
  return {
    type: types.LOGIN_USER_FAILURE,
  };
};

export const loggedOutUser = () => {
  toast.info(LOGGED_OUT_MESSAGE);
  userLoggedOut();
  return {
    type: types.LOGOUT_USER,
  };
};

export const loginUser = (response) => (dispatch) => {
  dispatch(loginUserBegin());

  return userLoginByGoogle(response.tokenId)
    .then(() => dispatch(loginUserSuccess()))
    .catch(() => toast.error(LOGIN_ERROR_MESSAGE));
};
