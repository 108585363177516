import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Footer from 'components/footer';
import EntryForm from 'components/entryForm';
import HistoryPage from 'components/historyPage';
import Archive from 'components/archive';
import Board from 'components/board';
import ArchiveBoard from 'components/board/ArchiveBoard';
import { FourOhFour } from 'components/ErrorPages';
import PolicyPage from 'components/policyPage';
import PolicyInfo from '../policyInfo';
import InformationPopup from '../modalNewPageInfo';

const AppRouter = () => (
  <BrowserRouter>
    <InformationPopup />
    <Switch>
      <Route path="/" component={EntryForm} exact />
      <Route path="/board/:id" component={Board} />
      <Route path="/history" component={HistoryPage} exact />
      <Route path="/archive" component={Archive} exact />
      <Route path="/archive/:id" component={Archive} />
      <Route path="/archiveBoard/:id" component={ArchiveBoard} />
      <Route path="/policy" component={PolicyPage} />
      <Route component={FourOhFour} />
    </Switch>
    <PolicyInfo />
    <Footer />
  </BrowserRouter>
);
export default AppRouter;
