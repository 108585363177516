import { getUserId } from 'root/userNameHelpers';

export const getActiveVotes = (votes, boardId) =>
  votes.filter((id) => id === getUserId(boardId));

export const getRemainingVotes = (votesPerUser, numberVotes) => {
  const remainingVotesNumber =
    votesPerUser - numberVotes > 0 ? votesPerUser - numberVotes : 0;
  return remainingVotesNumber;
};
