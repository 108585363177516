import React, { useState } from 'react';
import Header from 'components/header';
import Button from 'components/button';
import { downloadBoard } from 'root/utils';
import { useWindowWidth } from 'root/customHooks';

const ArchiveBoardHeader = React.memo(({ title, boardId }) => {
  const windowSize = useWindowWidth();
  const [optionsVisible, setOptionsVisible] = useState(
    !windowSize.isMobileView
  );

  const buttonsClass = optionsVisible ? '' : 'buttons--hidden';
  const iconClass = optionsVisible ? 'up' : 'down';

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  const handleDownload = async (type) => {
    try {
      const response = await downloadBoard(type, boardId);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.${type}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (error) {
      console.error('Something went wrong with download: ', error);
    }
  };

  const renderButtons = () => (
    <div
      className={`buttons buttons--main ${
        windowSize.isMobileView ? buttonsClass : ''
      }`}
    >
      <Button
        className="button button--white"
        onClick={() => handleDownload('csv')}
      >
        <i className="fas fa-file-download" /> Download CSV
      </Button>
      <Button
        className="button button--white"
        onClick={() => handleDownload('json')}
      >
        <i className="fas fa-file-code" /> Download JSON
      </Button>
      <Button
        className="button button--white"
        onClick={() => handleDownload('xlsx')}
      >
        <i className="fas fa-file-excel" /> Download XLSX
      </Button>
    </div>
  );

  return (
    <>
      <Header toUrl="/archive" goBackText="Go to archive" title={title} />
      <div className="buttons">
        {windowSize.isMobileView && (
          <div className="button--toggle-wrapper">
            <Button onClick={toggleOptions} className="button button--toggle">
              {optionsVisible ? 'Hide options ' : 'Show options '}
              <i className={`fas fa-angle-${iconClass}`} />
            </Button>
          </div>
        )}
        {renderButtons()}
      </div>
    </>
  );
});

ArchiveBoardHeader.displayName = 'ArchiveBoardHeader';

export default ArchiveBoardHeader;
