import React from 'react';
import 'components/ErrorPages/errorPages.scss';

const SomethingWentWrongPage = ({ text }) => (
  <div className="error-page">
    <h1 className="error-page--title">Something Went Wrong</h1>
    <section className="error-page--description">
      <p>{text}</p>
    </section>
  </div>
);

SomethingWentWrongPage.defaultProps = {
  text: 'Please try again in a few minutes',
};

export default SomethingWentWrongPage;
