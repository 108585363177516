import React from 'react';
import Popup from 'reactjs-popup';
import Button from 'components/button';
import { getUserId, getUserName } from 'root/userNameHelpers';
import { connect } from 'react-redux';

import 'components/popup/popup.scss';
import 'components/votesEdit/votesEdit.scss';
import { handlers } from 'root/utils';
import { toast } from 'react-toastify';
import { errorDictionary as error } from 'root/constants.js';

class ModalNameEdit extends React.PureComponent {
  state = {
    userNewName: getUserName() ?? '',
  };

  _userNameMaxLength = 100;

  handleChange = (event) => {
    this.setState({ userNewName: event.target.value });
  };

  changeUserName = (close) => {
    const { boardId, nameRegistry } = this.props;
    const { userNewName } = this.state;
    const userId = getUserId(boardId);
    if (nameRegistry[userId] === userNewName) {
      close();
    } else if (Object.values(nameRegistry).includes(userNewName)) {
      toast.warn(error.USERNAME_EXISTS);
    } else {
      const userName = userNewName.trim();
      this.setState({ userNewName: userName });
      if (userName.length > this._userNameMaxLength) {
        toast.warn('User name is too long');
      } else {
        handlers.changeUsername(boardId, userName, userId).then(close);
      }
    }
  };

  render() {
    const { userNewName } = this.state;
    return (
      <Popup
        className="votesEdit-popup popup-content--yellow"
        trigger={
          <Button className="button button--white">
            <i className="fas fa-user card__action--icon" /> Set name
          </Button>
        }
        modal
        defaultOpen={!getUserName()}
        closeOnDocumentClick={false}
        onClose={() => {
          this.setState({ userNewName: getUserName() ?? '' });
        }}
      >
        {(close) => (
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Set your name:</p>
              <Button className="delete" aria-label="close" onClick={close} />
            </header>
            <div className="content">
              <div className="field">
                <div className="control">
                  <input
                    className="input entry-form__input"
                    type="text"
                    placeholder="Write your name"
                    value={userNewName}
                    onChange={this.handleChange}
                    maxLength={this._userNameMaxLength}
                  />
                </div>
              </div>
            </div>
            <footer className="modal-card-foot">
              <Button
                className="button--medium button--yellow"
                disabled={!userNewName?.length || !userNewName.trim()}
                onClick={() => this.changeUserName(close)}
              >
                Save changes
              </Button>
            </footer>
          </div>
        )}
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  boardId: state.board.boardId,
  nameRegistry: state.board.nameRegistry,
});

export default connect(
  mapStateToProps,
  null
)(ModalNameEdit);
