import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { connect } from 'react-redux';
import { handlers } from 'root/utils';
import 'components/columnTitle/columnTitle.scss';
import { toast } from 'react-toastify';

class ColumnTitle extends React.Component {
  columnNameRef = React.createRef();

  state = { name: '' };

  componentDidMount() {
    this.setState({ name: this.props.title });
  }

  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title) {
      this.setState({ name: this.props.title });
    }
  }

  handleChange = (event) => this.setState({ name: event.target.value });

  _maxColumnNameLength = 300;

  handlePress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.columnNameRef.current.blur();
    }
  };

  handleColumnRename = () => {
    const { boardId, columnId } = this.props;
    const { name } = this.state;
    const nameTrimmed = name.trim();

    if (!nameTrimmed) {
      this.setState({ name: this.props.title });
      toast.info(
        'You cannot set an empty column name. You can delete this column using bin icon'
      );
    } else if (nameTrimmed.length > this._maxColumnNameLength) {
      this.setState({ name: nameTrimmed.slice(0, this._maxColumnNameLength) });
      toast.info(
        `Column title is too long. Maximum is ${this._maxColumnNameLength} characters`
      );
    } else {
      handlers.columnRename(boardId, columnId, name);
    }
  };

  render() {
    const { name } = this.state;

    return (
      <h1 className="label column--label">
        <span className="column--label__decorator" />
        <TextareaAutosize
          disabled={this.props.readOnly}
          name="column-title"
          ref={this.columnNameRef}
          value={name}
          id="column-title"
          className="column__title label"
          onChange={this.handleChange}
          onBlur={this.handleColumnRename}
          onKeyPress={this.handlePress}
          maxLength={this._maxColumnNameLength}
        />
      </h1>
    );
  }
}

const mapStateToProps = (state, { columnId }) => ({
  title: state.board.columns[columnId].name,
  boardId: state.board.boardId,
  readOnly: state.visible.readOnly,
});

export default connect(
  mapStateToProps,
  null
)(ColumnTitle);
