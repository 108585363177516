import * as types from '../actions/types';

export const initialState = {
  readOnly: false,
};

const visibleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_READ_ONLY_MODE:
      return {
        ...state,
        readOnly: true,
      };
    case types.SET_EDIT_MODE:
      return {
        ...state,
        readOnly: false,
      };
    default:
      return state;
  }
};

export default visibleReducer;
