import React from 'react';
import './header.scss';
import Link from 'components/link';

const Header = React.memo(({ toUrl, goBackText, title }) => (
  <div className="page__header">
    <Link className="header__back" to={toUrl}>
      <i className="fas fa-arrow-left back__icon" />
      <span>{goBackText}</span>
    </Link>

    <h2 className="header__title">{title}</h2>
  </div>
));

Header.displayName = 'Header';

export default Header;
export { default as HeaderLight } from './header-light';
