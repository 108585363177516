import React from 'react';
import Link from 'components/link';
import { connection, fetchBoards } from 'root/utils';
import withAuth from 'components/withAuth';
import Header from 'components/header';
import Button from 'components/button';
import './historyPage.scss';

class HistoryPage extends React.Component {
  pageSize = 10;

  heightOfOneHistoryCell = 54.4;

  sectionPadding = 64;

  state = {
    historyBoards: [],
    page: 0,
    error: false,
  };

  async componentDidMount() {
    try {
      const boards = await fetchBoards();
      this.setState({ historyBoards: boards });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
    connection.on('boardCreated', this.onBoardCreated);
    connection.on('boardRemoved', this.onBoardRemoved);
  }

  async componentWillUnmount() {
    connection.off('boardCreated', this.onBoardCreated);
    connection.off('boardRemoved', this.onBoardRemoved);
  }

  onBoardCreated = (boardCreated) => {
    const boards = [...this.state.historyBoards];
    this.setState({
      historyBoards: [boardCreated, ...boards],
    });
  };

  onBoardRemoved = (boardId) => {
    const boards = this.state.historyBoards.filter((b) => b.id !== boardId);
    this.setState({ historyBoards: [...boards] });

    if (this.state.page > this.maxPage()) {
      this.setPage(this.state.page - 1);
    }
  };

  currentPage = () =>
    this.state.historyBoards.slice(
      this.pageSize * this.state.page,
      this.pageSize * this.state.page + this.pageSize
    );

  maxPage = () =>
    Math.ceil(this.state.historyBoards.length / this.pageSize) - 1;

  middleNumbers = () => {
    const numbers = [];
    const maxPage = this.maxPage();
    const { page } = this.state;
    if (maxPage < 7)
      return [...Array(maxPage).keys()].filter((x) => x > 0 && x < maxPage);
    let leftHood = 1;
    let rightHood = 1;
    if (page < 4) {
      leftHood = page;
      rightHood = 4 - leftHood;
    } else if (page > maxPage - 4) {
      rightHood = maxPage - page;
      leftHood = 4 - rightHood;
    }

    let i = page - leftHood;
    while (i <= page + rightHood) {
      if (i > 0 && i < maxPage) numbers.push(i);
      i += 1;
    }

    return numbers;
  };

  setPage = (page) => {
    const maxPage = this.maxPage();
    if (page < 0 || page > maxPage) return;
    this.setState({ page });
  };

  lastPageItemIndex = () => {
    const index = this.state.page * this.pageSize + this.pageSize;
    return index < this.state.historyBoards.length
      ? index
      : this.state.historyBoards.length;
  };

  title = () => {
    if (this.state.error) return 'An error occurred';
    if (!this.state.historyBoards.length) return 'No boards found';
    return (
      `Active boards ${this.state.page * this.pageSize + 1} to` +
      ` ${this.lastPageItemIndex()} of ${this.state.historyBoards.length}`
    );
  };

  render() {
    const sectionStyle = {
      minHeight:
        this.pageSize * this.heightOfOneHistoryCell + this.sectionPadding,
    };
    return (
      <div className="history-page">
        <Header toUrl="/" goBackText="Go to main page" title={this.title()} />

        <section className="history-page__last-boards" style={sectionStyle}>
          {this.currentPage().map((board) => (
            <Link
              to={`/board/${board.id}`}
              key={board.id}
              className="history-page__link"
            >
              {board.name} <i className="fas fa-arrow-right" />
            </Link>
          ))}
        </section>
        {!this.state.historyBoards.length && !this.state.error && (
          <div className="history-page__shrug">¯\_(ツ)_/¯</div>
        )}

        {this.state.error && (
          <div>
            <div className="history-page__shrug">(ಠ_ರೃ)</div>
            <div>Well, this is unexpected.</div>
          </div>
        )}

        {this.maxPage() > 0 && (
          <div className="history-page__pagination">
            <Button
              className={`button button--medium button--white${
                this.state.page === 0 ? ' active' : ''
              }`}
              onClick={() => this.setPage(0)}
            >
              1
            </Button>
            {this.state.page > 3 && this.maxPage() > 6 && (
              <Button
                className="button button--medium button--white"
                onClick={() => this.setPage(this.state.page - 1)}
              >
                <i className="fas fa-angle-left" />
              </Button>
            )}
            {this.middleNumbers().map((p) => (
              <Button
                className={`button button--medium button--white${
                  this.state.page === p ? ' active' : ''
                }`}
                onClick={() => this.setPage(p)}
                key={p}
              >
                {p + 1}
              </Button>
            ))}
            {this.state.page < this.maxPage() - 3 && this.maxPage() > 6 && (
              <Button
                className="button button--medium button--white"
                onClick={() => this.setPage(this.state.page + 1)}
              >
                <i className="fas fa-angle-right" />
              </Button>
            )}
            <Button
              className={`button button--medium button--white${
                this.state.page === this.maxPage() ? ' active' : ''
              }`}
              onClick={() => this.setPage(this.maxPage())}
            >
              {this.maxPage() + 1}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withAuth(HistoryPage);
