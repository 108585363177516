import { keyBy, omit, cloneDeep } from 'lodash/fp';
import { getUserId, getUserName } from 'root/userNameHelpers';
import * as types from '../actions/types';

export const initialState = {
  columns: {},
  boardId: null,
  name: null,
  votesPerUser: null,
  votesVisibility: true,
  usedVotesPerActiveUser: null,
  sortType: 'order',
  moveColumnId: null,
  moveCardId: null,
  directionColumnId: null,
  isSavingBoard: false,
  cardIdForMerge: '',
  columnIdForMerge: '',
  nameRegistry: {},
};
const countVotesFromRemovedColumn = (state, action) => {
  const removedColumn = state.columns[action.payload.removedColumnId];
  let removedUserVotes = 0;
  const userId = getUserId(state.boardId);
  if (removedColumn.cards) {
    removedColumn.cards.forEach((card) => {
      card.votes.forEach((vote) => {
        if (vote === userId) {
          removedUserVotes += 1;
        }
      });
    });
  }

  return removedUserVotes;
};

const countVotesFromRemovedCard = (state, action) => {
  const { columnId, cardId } = action.payload.removeCardData;
  const removedColumn = state.columns[columnId];
  let removedUserVotes = 0;
  const userId = getUserId(state.boardId);
  if (action.payload.updateVotes && removedColumn.cards) {
    removedColumn.cards.forEach((card) => {
      if (card.id === cardId) {
        card.votes.forEach((vote) => {
          if (vote === userId) {
            removedUserVotes += 1;
          }
        });
      }
    });
  }

  return removedUserVotes;
};

const removedUserFromVotesTable = (card, action) => {
  const votesTable = card.votes;
  const indexOfUserId = votesTable.indexOf(action.payload.userId);
  votesTable.splice(indexOfUserId, 1);

  return votesTable;
};

const updateVote = (state, action, number) => {
  if (action.payload.userId === getUserId(state.boardId)) {
    return state.usedVotesPerActiveUser + number;
  }

  return state.usedVotesPerActiveUser;
};

const resetValuesWhenRemoveColumn = (state, action) => ({
  moveCardId:
    action.payload.removedColumnId === state.moveColumnId
      ? ''
      : state.moveCardId,
  moveColumnId:
    action.payload.removedColumnId === state.moveColumnId
      ? ''
      : state.moveColumnId,
  cardIdForMerge:
    action.payload.removedColumnId === state.columnIdForMerge
      ? ''
      : state.cardIdForMerge,
  columnIdForMerge:
    action.payload.removedColumnId === state.columnIdForMerge
      ? ''
      : state.columnIdForMerge,
});

const resetValuesWhenRemoveCard = (state, action) => ({
  moveCardId:
    action.payload.removeCardData.cardId === state.moveCardId
      ? ''
      : state.moveCardId,
  moveColumnId:
    action.payload.removeCardData.cardId === state.moveCardId
      ? ''
      : state.moveColumnId,
  cardIdForMerge:
    action.payload.removeCardData.cardId === state.cardIdForMerge
      ? ''
      : state.cardIdForMerge,
  columnIdForMerge:
    action.payload.removeCardData.cardId === state.cardIdForMerge
      ? ''
      : state.columnIdForMerge,
});

const changeUserIdAfterRejoined = (state, action) => {
  const { newUserId, oldUserId } = action.payload.rejoinedData;
  const changedColumns = cloneDeep(state.columns);
  const columns = Object.keys(changedColumns);

  for (const column of columns) {
    for (const card of changedColumns[column].cards) {
      card.votes = card.votes.map((vote) =>
        vote === oldUserId ? newUserId : vote
      );
    }
  }

  return changedColumns;
};

const indicateChangeInCardEditing = (
  state,
  { payload: { cardId, columnId, userName: editingUserName } },
  mutateSetFn
) => {
  const { boardId, nameRegistry } = state;
  let currentUser = nameRegistry[getUserId(boardId)];
  currentUser = currentUser || null;
  if (editingUserName === currentUser) {
    return state;
  }

  const column = state.columns[columnId];
  if (!cardId) {
    const usersCurrentlyAddingCards = new Set(column.usersCurrentlyAddingCards);
    if (mutateSetFn(usersCurrentlyAddingCards, editingUserName)) {
      return {
        ...state,
        columns: {
          ...state.columns,
          [columnId]: {
            ...column,
            usersCurrentlyAddingCards,
          },
        },
      };
    }
    return state;
  }

  return {
    ...state,
    columns: {
      ...state.columns,
      [columnId]: {
        ...column,
        cards: column.cards.map((card) => {
          if (card.id === cardId) {
            const editedBy = new Set(card.editedBy);
            if (mutateSetFn(editedBy, editingUserName)) {
              return { ...card, editedBy };
            }
          }
          return card;
        }),
      },
    },
  };
};

const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BOARD_ON_LOAD: {
      const { boardData } = action.payload;
      const userId = getUserId(boardData.id);

      return {
        ...state,
        columns: keyBy('id', boardData.columns),
        boardId: boardData.id,
        name: boardData.name,
        votesPerUser: boardData.votesPerUser,
        votesVisibility: boardData.votesVisible,
        usedVotesPerActiveUser: boardData.voteRegistry[userId] ?? 0,
        nameRegistry: boardData.nameRegistry,
      };
    }
    case types.USER_REJOINED: {
      return {
        ...state,
        columns: changeUserIdAfterRejoined(state, action),
      };
    }
    case types.ADD_COLUMN: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.column.id]: { ...action.payload.column, cards: [] },
        },
      };
    }
    case types.REMOVE_COLUMN: {
      return {
        ...state,
        columns: omit([action.payload.removedColumnId], state.columns),
        ...resetValuesWhenRemoveColumn(state, action),
        usedVotesPerActiveUser:
          state.usedVotesPerActiveUser -
          countVotesFromRemovedColumn(state, action),
      };
    }

    case types.SORT_CARD_IN_COLUMN: {
      return {
        ...state,
        columns: keyBy('id', action.payload.columns),
        sortType: state.sortType === 'order' ? 'voteCount' : 'order',
      };
    }
    case types.ADD_CARD: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.newCard.columnId]: {
            ...state.columns[action.payload.newCard.columnId],
            cards: [
              ...state.columns[action.payload.newCard.columnId].cards,
              action.payload.newCard.card,
            ],
          },
        },
      };
    }
    case types.EDIT_CARD: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.editedCardData.columnId]: {
            ...state.columns[action.payload.editedCardData.columnId],
            cards: state.columns[
              action.payload.editedCardData.columnId
            ].cards.map((card) => {
              if (card.id === action.payload.editedCardData.cardId) {
                card.text = action.payload.editedCardData.text;
              }

              return card;
            }),
          },
        },
      };
    }
    case types.INDICATE_EDITING_CARD:
      return indicateChangeInCardEditing(
        state,
        action,
        (setInstance, userName) =>
          !setInstance.has(userName) && setInstance.add(userName)
      );
    case types.STOP_EDITING_CARD:
      return indicateChangeInCardEditing(
        state,
        action,
        (setInstance, userName) => setInstance.delete(userName)
      );
    case types.REMOVE_CARD: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.removeCardData.columnId]: {
            ...state.columns[action.payload.removeCardData.columnId],
            cards: state.columns[
              action.payload.removeCardData.columnId
            ].cards.filter(
              (card) => card.id !== action.payload.removeCardData.cardId
            ),
          },
        },
        ...resetValuesWhenRemoveCard(state, action),
        usedVotesPerActiveUser:
          state.usedVotesPerActiveUser -
          countVotesFromRemovedCard(state, action),
      };
    }
    case types.REORDER_CARDS_IN_COLUMN: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.columnId]: {
            ...state.columns[action.payload.columnId],
            cards: state.columns[action.payload.columnId].cards.map((card) => ({
              ...card,
              order: action.payload.cardsOrder.find(
                (cardOrder) => cardOrder.id === card.id
              ).order,
            })),
          },
        },
      };
    }
    case types.MOVE_CARD_TO_ANOTHER_COLUMN: {
      const cardToMove = {
        ...state.columns[action.payload.columnId].cards.find(
          (card) => card.id === action.payload.cardId
        ),
        order: action.payload.order,
      };
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.columnId]: {
            ...state.columns[action.payload.columnId],
            cards: state.columns[action.payload.columnId].cards.filter(
              (card) => card.id !== action.payload.cardId
            ),
          },
          [action.payload.targetColumnId]: {
            ...state.columns[action.payload.targetColumnId],
            cards: [
              ...state.columns[action.payload.targetColumnId].cards.map(
                (card) => {
                  if (card.order >= action.payload.order) {
                    return { ...card, order: card.order + 1 };
                  }
                  return card;
                }
              ),
              cardToMove,
            ],
          },
        },
      };
    }
    case types.CHANGE_CARD_ORDER: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.columnId]: {
            ...state.columns[action.payload.columnId],
            cards: state.columns[action.payload.columnId].cards.map((card) => {
              if (card.id === action.payload.cardId) {
                return { ...card, order: action.payload.order };
              }
              if (card.order >= action.payload.order) {
                return { ...card, order: card.order + 1 };
              }
              return card;
            }),
          },
        },
      };
    }
    case types.ADD_VOTE: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.columnId]: {
            ...state.columns[action.payload.columnId],
            cards: state.columns[action.payload.columnId].cards.map((card) => {
              if (card.id === action.payload.cardId) {
                return {
                  ...card,
                  voteCount: card.voteCount + 1,
                  votes: [...card.votes, action.payload.userId],
                };
              }

              return card;
            }),
          },
        },
        usedVotesPerActiveUser: updateVote(state, action, 1),
      };
    }
    case types.REMOVE_VOTE: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.columnId]: {
            ...state.columns[action.payload.columnId],
            cards: state.columns[action.payload.columnId].cards.map((card) => {
              if (card.id === action.payload.cardId) {
                return {
                  ...card,
                  voteCount: card.voteCount - 1,
                  votes: removedUserFromVotesTable(card, action),
                };
              }

              return card;
            }),
          },
        },
        usedVotesPerActiveUser: updateVote(state, action, -1),
      };
    }
    case types.TOGGLE_VOTE_VISIBILITY: {
      return {
        ...state,
        votesVisibility: !state.votesVisibility,
      };
    }
    case types.SHOW_VOTES: {
      return {
        ...state,
        votesVisibility: true,
      };
    }
    case types.SET_MAX_VOTES_PER_USER: {
      return {
        ...state,
        votesPerUser: action.payload.votesPerUser,
      };
    }
    case types.RENAME_COLUMN: {
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.payload.columnId]: {
            ...state.columns[action.payload.columnId],
            name: action.payload.name,
          },
        },
      };
    }
    case types.ON_START_MOVING_CARD: {
      return {
        ...state,
        moveColumnId:
          action.payload.cardId === state.moveCardId
            ? null
            : action.payload.columnId,
        moveCardId:
          action.payload.cardId === state.moveCardId
            ? null
            : action.payload.cardId,
      };
    }
    case types.MOVE_CARD_SUCCESS: {
      return {
        ...state,
        moveColumnId: null,
        moveCardId: null,
      };
    }

    case types.CHOOSE_CARD_ID_FOR_MERGE: {
      return {
        ...state,
        cardIdForMerge: action.payload.cardIdForMerge,
        columnIdForMerge: action.payload.columnIdForMerge,
      };
    }
    case types.MERGE_CARD_SUCCESS: {
      return {
        ...state,
        cardIdForMerge: '',
        columnIdForMerge: '',
      };
    }
    case types.SET_COLUMN_ORDER: {
      const columns = action.payload.itemOrder.reduce((res, current) => {
        res[current.id] = {
          ...state.columns[current.id],
          order: current.order,
        };
        return res;
      }, {});

      return {
        ...state,
        columns,
      };
    }
    case types.UPDATE_NAMES_REGISTRY: {
      return {
        ...state,
        nameRegistry: action.payload.nameRegistry,
      };
    }
    default:
      return state;
  }
};

export default boardReducer;
