import React, { useState } from 'react';
import Button from 'components/button';
import { apiAuthorizedCall } from 'root/utils';

const AddFolder = React.memo((props) => {
  const { getDirectories, parentId } = props;

  const [newName, setNewName] = useState('');

  const handleInputChange = (e) => {
    setNewName(e.target.value);
  };

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      createDirectory();
    }
  };

  const createDirectory = async () => {
    const trimmedName = newName.trim();
    if (!trimmedName) return false;

    try {
      let response = null;
      if (parentId) {
        response = await apiAuthorizedCall(
          `/api/archive/createDirectory?name=${trimmedName}&parentId=${parentId}`,
          'post'
        );
      } else {
        response = await apiAuthorizedCall(
          `/api/archive/createDirectory?name=${trimmedName}`,
          'post'
        );
      }

      if (response) {
        setNewName('');
        getDirectories();
      }
    } catch (error) {
      console.error('Error occured while creating directory: ', error);
    }
  };

  return (
    <div className="row archive-page__input-container">
      <input
        className="col-xs-8 col-md-4 input archive-page__input"
        name="newName"
        onChange={handleInputChange}
        value={newName}
        onKeyUp={handlePressEnter}
      />
      <Button
        className="col-md-1 button button--small button--white"
        onClick={createDirectory}
      >
        Add Folder
      </Button>
    </div>
  );
});

AddFolder.displayName = 'AddFolder';

export default AddFolder;
