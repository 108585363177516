import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Link from 'components/link';
import 'components/ErrorPages/errorPages.scss';

class FourOhFour extends Component {
  interval = null;

  state = { seconds: 10 };

  tick = () => {
    this.setState((prevState) => ({
      seconds: prevState.seconds - 1,
    }));
  };

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  renderContent = () => {
    const { seconds } = this.state;
    const { text } = this.props;

    return (
      <div className="error-page">
        <h1 className="error-page--title">Awww... Don't Cry</h1>
        <section className="error-page--description">
          <p>{text}</p>
          <p>
            Click and go to <Link to="/">Home Page</Link> or you will be
            redirect in {seconds}
          </p>
        </section>
      </div>
    );
  };

  render() {
    const { seconds } = this.state;
    return seconds <= 0 ? <Redirect to="/" /> : this.renderContent();
  }
}

FourOhFour.defaultProps = {
  text: "It's just a 404 Error!",
};

export default FourOhFour;
