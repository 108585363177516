import React from 'react';
import './header.scss';
import { Link } from 'react-router-dom';

const HeaderLight = React.memo(({ toUrl, title }) => (
  <div className="page__header">
    <a className="header__back" href={toUrl}>
      <img
        src={require('../../assets/images/boldare.svg')}
        alt="Boldare logo"
      />
    </a>

    <h2 className="header__title">{title}</h2>
  </div>
));

HeaderLight.displayName = 'HeaderLight';

export default HeaderLight;
