import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { handlers } from 'root/utils';
import { connect } from 'react-redux';
import * as boardAction from 'root/store/actions/board';

class DragDropContainer extends React.Component {
  onDragEnd = ({ destination, source, draggableId: cardId }) => {
    const { boardId } = this.props;
    if (destination) {
      if (destination.droppableId !== source.droppableId) {
        this.props.moveCardToAnotherColumn(
          source.droppableId,
          destination.droppableId,
          cardId,
          destination.index
        );
        handlers.cardChangeColumn(
          boardId,
          source.droppableId,
          cardId,
          destination.droppableId,
          destination.index
        );
      } else if (destination.index !== source.index) {
        this.props.changeCardOrder(
          destination.droppableId,
          cardId,
          destination.index
        );
        handlers.cardSetOrder(
          boardId,
          destination.droppableId,
          cardId,
          destination.index
        );
      }
    }
  };

  render() {
    const { children } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>{children}</DragDropContext>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  moveCardToAnotherColumn: (columnId, targetColumnId, cardId, order) =>
    dispatch(
      boardAction.moveCardToAnotherColumn(
        columnId,
        targetColumnId,
        cardId,
        order
      )
    ),
  changeCardOrder: (columnId, cardId, order) => {
    dispatch(boardAction.changeCardOrder(columnId, cardId, order));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(DragDropContainer);
