import React, { useState } from 'react';
import Link from 'components/link';
import Button from 'components/button';
import 'components/policyInfo/policyInfo.scss';
import { isFullAppVersion } from 'root/utils';

const PolicyInfo = () => {
  const initialPolicyAgreement = localStorage.getItem('policyAgreement');

  const [policyAgreement, setPolicyAgreement] = useState(
    initialPolicyAgreement
  );

  const handleConfirmation = () => {
    localStorage.setItem('policyAgreement', true);
    setPolicyAgreement(true);
  };

  return (
    !isFullAppVersion() &&
    !policyAgreement && (
      <div className="policy-info">
        <div className="policy-info__text">
          By continuing to browse the site you are agreeing to our
          <Link className="policy-info__link" to="/policy">
            privacy policy
          </Link>
          .
        </div>
        <Button
          className="policy-info__button button--medium button--yellow"
          onClick={handleConfirmation}
        >
          Ok, I agree
        </Button>
      </div>
    )
  );
};

export default PolicyInfo;
