import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { keyBy, values, sortBy, flow, reverse } from 'lodash/fp';
import { connect } from 'react-redux';
import Card from 'components/card';
import NewCardAdd from 'components/newCardAdd';
import AddingCardIndicator from 'components/addingCardIndicator';

const setOrderBy = (param, votesVisible) => {
  const isSortingByVoteCount = param === 'voteCount';

  const sortParameter = votesVisible ? param : 'order';

  return flow(
    values,
    sortBy(
      isSortingByVoteCount
        ? [sortParameter, (card) => -card.order]
        : sortParameter
    ),
    isSortingByVoteCount && votesVisible ? reverse : (arr) => arr
  );
};

class CardsList extends React.Component {
  render() {
    const {
      sortType,
      cards,
      boardId,
      columnId,
      readOnly,
      usersCurrentlyAddingCards,
      votesVisibility,
    } = this.props;
    const cardsById = keyBy('id', cards);
    const sortedCards = setOrderBy(sortType, votesVisibility)(cardsById);
    const cardsCount = sortedCards.length;
    const isTop = cardsCount > 1 && 'top';
    const isBottom = cardsCount && 'bottom';

    return (
      <Droppable droppableId={columnId} type="CARD">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {!readOnly && cardsCount > 1 && (
              <NewCardAdd order={isTop} boardId={boardId} columnId={columnId} />
            )}
            <div>
              {sortedCards.map((card, idx) => (
                <Card
                  boardId={boardId}
                  columnId={columnId}
                  key={card.id}
                  text={card.text}
                  cardsCount={cardsCount}
                  voteCount={card.voteCount}
                  votes={card.votes}
                  cardId={card.id}
                  authors={card.authors}
                  editedBy={card.editedBy}
                  indexInList={idx}
                />
              ))}
              {provided.placeholder}
              {usersCurrentlyAddingCards &&
                usersCurrentlyAddingCards.size > 0 && (
                  <AddingCardIndicator
                    usersCurrentlyAddingCards={usersCurrentlyAddingCards}
                  />
                )}
            </div>
            {!readOnly && (
              <NewCardAdd
                order={isBottom}
                boardId={boardId}
                columnId={columnId}
              />
            )}
          </div>
        )}
      </Droppable>
    );
  }
}

const mapStateToProps = (state, { columnId }) => ({
  cards: state.board.columns[columnId].cards,
  boardId: state.board.boardId,
  readOnly: state.visible.readOnly,
  sortType: state.board.sortType,
  votesVisibility: state.board.votesVisibility,
});

export default connect(
  mapStateToProps,
  null
)(CardsList);
