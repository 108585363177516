/* eslint-disable no-console */
import * as signalR from '@aspnet/signalr';
import { getUserId } from 'root/userNameHelpers';

class SignalRConnection {
  callbackRegistry = [];

  setConnection = async () => {
    console.log('Setting connection');
    this.signal = new signalR.HubConnectionBuilder()
      .withUrl(`${API_URL}/boardHub`)
      .build();

    for (const r of this.callbackRegistry) {
      this.signal.on(r.method, r.delegate);
    }

    this.signal.onclose(() => {
      console.log('Closing connection...');

      setTimeout(async () => {
        console.log('Reconnecting....');
        await this.setConnection();
        const boardId = localStorage.getItem('boardId');
        if (boardId) this.joinBoard(boardId);
      }, 3000);
    });

    try {
      console.log('Attempting connection...');
      await this.signal.start();
    } catch (e) {
      console.log('Connection failed', e);
      setTimeout(async () => {
        await this.setConnection();
        const boardId = localStorage.getItem('boardId');
        if (boardId) this.joinBoard(boardId);
      }, 3000);
    }
  };

  on = (method, delegate) => {
    this.callbackRegistry.push({ method, delegate });
    this.signal.on(method, delegate);
  };

  off = (_, delegate) => {
    for (const r of [...this.callbackRegistry]) {
      if (r.delegate === delegate) {
        this.signal.off(r.method, delegate);
        this.callbackRegistry.splice(this.callbackRegistry.indexOf(r), 1);
      }
    }
  };

  createBoard = ({
    name,
    columns = ['Went well', 'To improve', 'Actions'],
    maxVotes,
    autoJoin = false,
  }) => {
    if (name) {
      this.signal.invoke('CreateBoard', name, columns, maxVotes, autoJoin);
    } else {
      console.error('Board name not specified');
    }
  };

  joinBoard = (boardId) => {
    const userId = getUserId(boardId);

    if (!userId) {
      return this.signal.invoke('Join', boardId);
    }
    return this.signal.invoke('Rejoin', boardId, userId);
  };

  AddVote = ({ ...params }) => {
    this.signal.invoke('AddVote', ...params);
  };
}

export default SignalRConnection;
