import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import {
  loginUser,
  loginUserFailure,
  loggedOutUser,
} from 'root/store/actions/auth';

import Button from 'components/button';

const LoginByGoogle = ({
  loggedInButtonText,
  loggedOutButtonText,
  buttonClassNames,
  isUserLoggedIn,
  handleLoginUser,
  handleLoginUserFailure,
  handleLoggedOutUser,
}) => (
  <>
    {isUserLoggedIn ? (
      <Button className={buttonClassNames} onClick={handleLoggedOutUser}>
        <i className="fas fa-sign-out-alt" />
        {` ${loggedInButtonText}`}
      </Button>
    ) : (
      <GoogleLogin
        clientId="816399755506-am4hi4bpr482mvl1jvnc9psrmsfndc6n.apps.googleusercontent.com"
        render={(renderProps) => (
          <Button
            className={buttonClassNames}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <i className="fas fa-sign-in-alt" />
            {` ${loggedOutButtonText}`}
          </Button>
        )}
        onSuccess={handleLoginUser}
        onFailure={handleLoginUserFailure}
        cookiePolicy="single_host_origin"
      />
    )}
  </>
);

LoginByGoogle.defaultProps = {
  loggedInButtonText: 'Logout',
  loggedOutButtonText: 'Login',
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.auth.isUserLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  handleLoginUser: (response) => dispatch(loginUser(response)),
  handleLoginUserFailure: (response) => dispatch(loginUserFailure(response)),
  handleLoggedOutUser: () => dispatch(loggedOutUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginByGoogle);
