import * as types from './types';

export const boardOnLoad = (boardData) => ({
  type: types.BOARD_ON_LOAD,
  payload: {
    boardData,
  },
});

export const userRejoined = (rejoinedData) => ({
  type: types.USER_REJOINED,
  payload: {
    rejoinedData,
  },
});

export const addColumn = (column) => ({
  type: types.ADD_COLUMN,
  payload: {
    column,
  },
});

export const removeColumn = (removedColumnId) => ({
  type: types.REMOVE_COLUMN,
  payload: {
    removedColumnId,
  },
});

export const addCard = (newCard) => ({
  type: types.ADD_CARD,
  payload: {
    newCard,
  },
});

export const removeCard = (removeCardData, updateVotes) => ({
  type: types.REMOVE_CARD,
  payload: {
    removeCardData,
    updateVotes,
  },
});

export const editCard = (editedCardData) => ({
  type: types.EDIT_CARD,
  payload: {
    editedCardData,
  },
});

export const indicateEditingCard = (columnId, cardId, userName) => ({
  type: types.INDICATE_EDITING_CARD,
  payload: {
    columnId,
    cardId,
    userName,
  },
});

export const stopEditingCard = (columnId, cardId, userName) => ({
  type: types.STOP_EDITING_CARD,
  payload: {
    columnId,
    cardId,
    userName,
  },
});

export const reorderCardsInColumn = (columnId, cardsOrder) => ({
  type: types.REORDER_CARDS_IN_COLUMN,
  payload: {
    columnId,
    cardsOrder,
  },
});

export const moveCardToAnotherColumn = (
  columnId,
  targetColumnId,
  cardId,
  order
) => ({
  type: types.MOVE_CARD_TO_ANOTHER_COLUMN,
  payload: {
    columnId,
    targetColumnId,
    cardId,
    order,
  },
});

export const changeCardOrder = (columnId, cardId, order) => ({
  type: types.CHANGE_CARD_ORDER,
  payload: {
    columnId,
    cardId,
    order,
  },
});

export const addVote = (columnId, cardId, userId) => ({
  type: types.ADD_VOTE,
  payload: {
    columnId,
    cardId,
    userId,
  },
});

export const removeVote = (columnId, cardId, userId) => ({
  type: types.REMOVE_VOTE,
  payload: {
    columnId,
    cardId,
    userId,
  },
});

export const toggleVoteVisibility = () => ({
  type: types.TOGGLE_VOTE_VISIBILITY,
});

export const showVotes = () => ({
  type: types.SHOW_VOTES,
});

export const setMaxVotesPerUser = (votesPerUser) => ({
  type: types.SET_MAX_VOTES_PER_USER,
  payload: {
    votesPerUser,
  },
});

export const renameColumn = (columnId, name) => ({
  type: types.RENAME_COLUMN,
  payload: {
    columnId,
    name,
  },
});

export const onStartMovingCard = (cardId, columnId) => ({
  type: types.ON_START_MOVING_CARD,
  payload: {
    columnId,
    cardId,
  },
});

export const moveCardSuccess = () => ({
  type: types.MOVE_CARD_SUCCESS,
});

export const chooseCardIdForMerge = (cardIdForMerge, columnIdForMerge) => ({
  type: types.CHOOSE_CARD_ID_FOR_MERGE,
  payload: {
    cardIdForMerge,
    columnIdForMerge,
  },
});

export const mergeCardSuccess = () => ({
  type: types.MERGE_CARD_SUCCESS,
});

export const sortCardInColumn = (columns) => ({
  type: types.SORT_CARD_IN_COLUMN,
  payload: {
    columns,
  },
});

export const setColumnOrder = (itemOrder) => ({
  type: types.SET_COLUMN_ORDER,
  payload: {
    itemOrder,
  },
});

export const updateNamesRegistry = (nameRegistry) => ({
  type: types.UPDATE_NAMES_REGISTRY,
  payload: {
    nameRegistry,
  },
});
