import React, { useState, createRef } from 'react';
import { apiAuthorizedCall } from 'root/utils';
import Link from 'components/link';
import isEmpty from 'lodash/isEmpty';

const Directories = ({
  id,
  name,
  activeBoardId,
  activeDirectoryId,
  activeRenameDirectoryId,
  handleDirectoryClick,
  setRenameDirectoryId,
  renameDirectoryFromList,
  removeDirectoryFromList,
  moveDirectoryOrBoard,
}) => {
  const [state, setState] = useState({
    renameDirectoryNewName: name,
    renameDirectoryOldName: '',
  });
  const [isEditMode, setEditMode] = useState(false);
  const directoryNameInput = createRef();

  const directoryClick = () => handleDirectoryClick(id);

  const removeActiveDirectory = (e) => {
    e.stopPropagation();
    handleDirectoryClick('');
  };

  const handleInputChange = (event) => {
    setState({ [event.target.name]: event.target.value });
  };

  const handleMoveDirectoryOrBoard = () => {
    moveDirectoryOrBoard(id);
  };

  const handleStartRenameDirectory = async (e) => {
    if (activeRenameDirectoryId === id) {
      setRenameDirectoryId('');
      setState({
        renameDirectoryNewName: '',
        renameDirectoryOldName: '',
      });
    }

    setEditMode(true);
    setRenameDirectoryId(id);
    setState({
      renameDirectoryNewName: name,
      renameDirectoryOldName: name,
    });

    if (!isEmpty(directoryNameInput.current)) {
      directoryNameInput.current.focus();
    }
  };

  const renameDirectory = async () => {
    if (
      !state.renameDirectoryNewName.trim() ||
      state.renameDirectoryNewName === state.renameDirectoryOldName
    ) {
      return false;
    }

    const response = await apiAuthorizedCall(
      `/api/archive/renameDirectory?directoryId=${activeRenameDirectoryId}&name=${state.renameDirectoryNewName}`
    );

    if (response.ok) {
      renameDirectoryFromList(
        state.renameDirectoryNewName,
        activeRenameDirectoryId
      );
      setRenameDirectoryId('');
    }
  };

  const handleDeleteDirectory = async () => {
    const response = await apiAuthorizedCall(
      `/api/archive/deleteDirectory?directoryId=${id}`,
      'delete'
    );

    if (response.ok) {
      removeDirectoryFromList(id);
      handleDirectoryClick(id);
    }
  };

  const handlePressInputRename = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      renameDirectory();
      directoryNameInput.current.blur();
    }

    if (e.key === 'Escape') {
      directoryNameInput.current.blur();
    }
  };

  const handleInputBlur = () => {
    setRenameDirectoryId('');
    setEditMode(false);
  };

  return (
    <div
      className={`archive-page__link ${
        activeDirectoryId === id ? 'active' : ''
      }`}
      onClick={directoryClick}
    >
      <div className="archive-page__directory-name--wrapper">
        <i className="fas fa-folder archive-page__icon" />
        {/* {activeRenameDirectoryId === id ? (
          <input
            className="input archive-page__input"
            name="renameDirectoryNewName"
            value={state.renameDirectoryNewName}
            onChange={handleInputChange}
            onBlur={renameDirectory}
            onKeyUp={handlePressInputRename}
            ref={directoryNameInput}
          />
        ) : <p>{name}</p>} */}
        <input
          className={`input archive-page__name-input ${
            isEditMode ? 'archive-page__name-input--active' : ''
          }`}
          name="renameDirectoryNewName"
          value={state.renameDirectoryNewName}
          onClick={handleStartRenameDirectory}
          onChange={handleInputChange}
          onKeyUp={handlePressInputRename}
          onBlur={handleInputBlur}
          ref={directoryNameInput}
        />
      </div>
      <div className="archive-page__action-container">
        {(activeDirectoryId || activeBoardId) && activeDirectoryId !== id && (
          <div
            className="archive-page__icon--wrapper"
            onClick={handleMoveDirectoryOrBoard}
          >
            <i
              className="fas fa-caret-square-down card__action--icon"
              title="Move to"
            />
          </div>
        )}
        <div
          className="archive-page__icon--wrapper"
          onClick={handleStartRenameDirectory}
        >
          <i className="fas fa-i-cursor" title="Rename" />
        </div>
        <div
          className="archive-page__icon--wrapper"
          onClick={handleDeleteDirectory}
        >
          <i className="fas fa-trash-alt" title="Delete" />
        </div>
        <Link
          className="archive-page__icon--wrapper"
          to={`/archive/${id}`}
          key={id}
          onClick={removeActiveDirectory}
        >
          <i className="fas fa-arrow-right" title="Visit" />
        </Link>
      </div>
    </div>
  );
};

export default Directories;
