import React from 'react';
import { version } from 'package';
import Link from 'components/link';
import 'components/footer/footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__content">
      <div className="footer__description-wrapper">
        <a
          href="https://www.boldare.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={require('../../assets/images/boldare-white.svg')}
            className="footer__logo"
            alt="Boldare logo"
            title="Boldare logo"
          />
        </a>
        <p className="footer__contact">
          The digital product design & development company. <br />
          Contact us:{' '}
          <a
            className="footer__email"
            href="mailto:hello@boldare.com"
            rel=""
            target="_self"
          >
            hello@boldare.com
          </a>
        </p>
        <p className="footer__policy">
          Our
          <Link className="policy-info__link" to="/policy">
            privacy policy
          </Link>
        </p>
      </div>
      <div className="footer__places hide-mobile">
        <div className="footer__place">
          <p className="footer__adress--bold">HQ Gliwice</p>
          <p className="footer__address">
            ul. Królowej Bony 13
            <br />
            44-100 Gliwice, Poland
            <br />
            +48 32 739 09 00
          </p>
        </div>
        <div className="footer__place">
          <p className="footer__adress--bold">Warsaw</p>
          <p className="footer__address">
            ul. Jasna 24
            <br />
            00-054 Warsaw, Poland
            <br />
            +48 32 739 09 00
          </p>
        </div>
        <div className="footer__place">
          <p className="footer__adress--bold">Wroclaw</p>
          <p className="footer__address">
            Wyspa Słodowa 7
            <br />
            50-266 Wroclaw, Poland
            <br />
            +48 32 739 09 00
          </p>
        </div>
        <div className="footer__place">
          <p className="footer__adress--bold">Cracow</p>
          <p className="footer__address">
            ul. Olszańska 7
            <br />
            31-513 Cracow, Poland
            <br />
            +48 32 739 09 00
          </p>
        </div>
      </div>
    </div>
    <div className="footer__copyrights">
      <span>Version {version}</span>
      <span>© {new Date().getFullYear()} Boldare. All rights reserved.</span>
    </div>
  </footer>
);

export default Footer;
