const windowGlobal = typeof window !== 'undefined' && window;
export const MEDIA_QUERY = {
  TABLET: 768,
};
export const MOBILE_VIEW = windowGlobal.innerWidth <= MEDIA_QUERY.TABLET;
export const LIMIT_VOTES_PER_USER = 50;

export const errorDictionary = {
  BOARD_CREATION_FAILED: 'Failed to create a board. No surfing for you today.',
  BOARD_REMOVAL_FAILED: 'Failed to remove a board. Are you done ironing?',
  BOARD_RENAMING_FAILED:
    'Failed to rename a board. I guess it likes its name after all.',
  JOINING_FAILED:
    "Failed to join a board. Maybe it's not there? Maybe YOU are not there?",
  CARD_ADDING_FAILED:
    'Failed to add a card. The deck is full. Wait, no, wrong app.',
  CARD_REMOVAL_FAILED:
    "Failed to remove a card. It's either settled down or already not there.",
  CARD_EDITING_FAILED: 'Failed to edit a card. You dirty, dirty cheater.',
  CARD_REORDERING_FAILED: 'Failed to change the order of the cards.',
  CARD_MERGING_FAILED:
    'Failed to merge the cards. They value their personal freedom.',
  VOTE_ADDING_FAILED: 'Voting failed. Are you of legal age?',
  VOTE_REMOVAL_FAILED:
    'Failed to remove the vote. Your antidemocratic efforts have been noted.',
  VOTE_VISIBILITY_CHANGING_FAILED:
    'Failed to change the vote visibility. Try closing/opening your eyes.',
  COLUMN_ADDING_FAILED:
    'Failed to add a column. Maybe, just maybe, we have all we need.',
  COLUMN_REMOVAL_FAILED:
    'Failed to remove a column. Warning: buildings might collapse if unsupported.',
  COLUMN_RENAMING_FAILED:
    "Failed to rename a column. Do you think you're their owner or something?",
  COLUMN_REORDERING_FAILED:
    'Failed to change column order. This architectural juggling is unacceptable.',
  AVAILABLE_VOTES_COUNT_CHANGE_FAILED:
    'Failed to change max available votes. What were you thinking?',
  CARD_COLUMN_CHANGE_FAILED:
    "Failed to change card's column. I hear there are columns in Greece.",
  NAME_CHANGE_FAILED: 'This name cannot be used',
  USERNAME_EXISTS: 'Sorry, this user name is taken',
};
