import React, { useEffect, useState } from 'react';
import { apiAuthorizedCall } from 'root/utils';
import { SomethingWentWrongPage, FourOhFour } from 'components/ErrorPages';
import SpinnerPage from 'components/spinner/SpinnerPage';
import Column from 'components/column';
import DragDropContainer from 'components/board/DragDropContainer';

import { values } from 'lodash/fp';
import { connect } from 'react-redux';
import * as boardAction from 'root/store/actions/board';
import * as visibleAction from 'root/store/actions/visible';
import ArchiveBoardHeader from './ArchiveBoardHeader';

const ArchiveBoard = (props) => {
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBoardExists, setIsBoardExists] = useState(false);
  const columnColors = ['blue', 'red', 'violet', 'yellow', 'marine'];

  useEffect(() => {
    async function fetchBoard(id) {
      try {
        const response = await apiAuthorizedCall(
          `/api/archive/getBoard?boardId=${id}`
        );

        if (response.ok) {
          const result = await response.json();
          props.handleBoardOnLoad(result);
          setIsBoardExists(true);
        } else {
          setIsBoardExists(false);
        }
        setIsLoaded(true);
      } catch (error) {
        setError(true);
      }
    }
    const boardId = props.match.params.id;
    props.handleSetToReadOnlyMode();
    fetchBoard(boardId);
  }, []);

  if (isLoaded && !isBoardExists) {
    return <FourOhFour text="Archive board does not exist." />;
  }

  if (error) {
    return <SomethingWentWrongPage />;
  }

  if (!isLoaded) {
    return <SpinnerPage />;
  }

  return (
    <div className="board-container">
      <ArchiveBoardHeader title={props.name} boardId={props.boardId} />
      <div className="columns columns-container">
        <DragDropContainer boardId={props.boardId}>
          {values(props.columns)
            .sort((a, b) => a.order - b.order)
            .map((column, index) => (
              <Column
                key={column.id}
                columnId={column.id}
                color={
                  column.color
                    ? column.color
                    : columnColors[index % columnColors.length]
                }
                name={column.name}
                handleRemove={() => {}}
              />
            ))}
        </DragDropContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  columns: state.board.columns,
  boardId: state.board.boardId,
  name: state.board.name,
});

const mapDispatchToProps = (dispatch) => ({
  handleBoardOnLoad: (boardData) =>
    dispatch(boardAction.boardOnLoad(boardData)),
  handleSetToReadOnlyMode: () =>
    dispatch(visibleAction.handleSetToReadOnlyMode()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveBoard);
