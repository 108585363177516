import React from 'react';

const Dots = ({ activeUserVotes }) =>
  activeUserVotes.map((_, index) => (
    <div className="votes__action" key={`card-icon-${index}`}>
      <i className="fas fa-circle card--action__icon" />
    </div>
  ));

export default Dots;
