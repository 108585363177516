import React from 'react';
import Popup from 'reactjs-popup';
import Button from 'components/button';
import 'components/popup/popup.scss';

const ConfirmPopup = ({ onApprove, text, trigger, onOpen, onClose }) => (
  <Popup
    className="popup-content--gray"
    trigger={trigger}
    onOpen={onOpen}
    onClose={onClose}
    modal
  >
    {(close) => (
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{text}</p>
          <Button className="delete" aria-label="close" onClick={close} />
        </header>
        <footer className="modal-card-foot">
          <Button className="button--medium button--gray" onClick={close}>
            No
          </Button>
          <Button
            className="button--medium button--yellow"
            onClick={() => {
              onApprove();
              close();
            }}
          >
            Yes
          </Button>
        </footer>
      </div>
    )}
  </Popup>
);

export default ConfirmPopup;
