import React from 'react';
import GoBackButton from 'components/goBackButton';

import 'components/policyPage/policyPage.scss';

const PolicyPage = () => (
  <div className="policy-page">
    <div className="policy-content">
      <GoBackButton className="button--simple policy-page__button" />
      <h1 className="policy-content__header">
        Privacy Policy of Sprint Retrospective Tool
      </h1>
      This privacy policy (“Policy”) describe the way Sprint Retrospective Tool
      collects and process your Data.
      <ol className="policy-list--roman">
        <li>
          <h2 className="policy-list__header">Definitions</h2>
          <ol className="policy-sublist">
            <li>
              <label className="policy-sublist__bold">Services</label>
              means the services we provide through our Software “Sprint
              Retrospective Tool”;
            </li>
            <li>
              <label className="policy-sublist__bold">Website</label>
              means Sprint Retrospective Tool,
              <a
                className="policy-info__link"
                href={process.env.LANDING_PAGE_URL}
              >
                https://sprintretro.boldare.com
              </a>
              , adopted to operate our Services;
            </li>
            <li>
              <label className="policy-sublist__bold">Data</label>
              means any information collected;
            </li>
            <li>
              <label className="policy-sublist__bold">Personal Data</label>
              means any personal information collected from an individual that
              identifies a natural person (i.e. e-mail, date of birth, name);
            </li>
            <li>
              <label className="policy-sublist__bold">Processing</label>
              means any operation or set of operations that uses personal data;
            </li>
            <li>
              <label className="policy-sublist__bold">Controller</label>
              means any natural or legal person, whether public or private, in
              charge of decisions on the processing of personal data;
            </li>
            <li>
              <label className="policy-sublist__bold">Processor</label>
              means any natural or legal person, whether public or private, who
              processes personal data on behalf of the controller;
            </li>
            <li>
              <label className="policy-sublist__bold">Recipient</label>
              means any natural or legal person, whether public or private, for
              whom the personal data collected are reported to;
            </li>
            <li>
              <label className="policy-sublist__bold">Third Party</label>
              means any natural or legal person, whether public or private,
              under the control of the controller or the processor, is
              authorized to perform the processing of personal data.
            </li>
            <li>
              <label className="policy-sublist__bold">GDPR</label>
              refers to the Regulation (EU) 2016/679 (General Data Protection
              Regulation).
            </li>
          </ol>
        </li>
        <li>
          <h2 className="policy-list__header">Data collecting</h2>
          <p className="policy-list__section">
            <label className="policy-sublist__bold">Personal Data:</label> We
            don’t collect Personal Data directly from you. When you use our
            Sprint Retrospective Tool, you don’t us any personal data.
          </p>
          <p className="policy-list__section">
            <label className="policy-sublist__bold">Data:</label>Indirectly
            and/or automatically generated or collected information that does
            not contain any personal information. We use those Data to improve
            your user experience, for example, your IP address.
          </p>
          <p className="policy-list__section">
            We emphasize all the content created, in reference to content in the
            Sprint Retrospective Tool cannot be accessed with legal ways by a
            non-authorized User or user which receive access from You.
          </p>
          <p className="policy-list__section">
            You can have access and request the modification or exclusion of
            your personal data (if there will be need to collect it later) at
            any time. For that, we request that you send a requirement to the
            following e-mail address:
            <label className="policy-sublist__bold"> hello@boldare.com</label>
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">Liability</h2>
          <p className="policy-list__section">
            This service comes with absolutely no warranties of any kind,
            including (without limitation to) the implied warranties of
            merchantability and fitness for a particular purpose. Your use of
            this service is at your sole risk and at your own responsibility. We
            do not guarantee, represent or warrant that your use of this service
            will be uninterrupted, secure, timely or error-free.
          </p>
          <p className="policy-list__section">
            We will not be responsible for any losses or damages incurred to you
            or to any third party as a result of your use of this service.
          </p>
          <p className="policy-list__section">
            The service may be modified or discontinued at any time without any
            notice to you. We may change the way the service works in a way that
            adversely impacts you – for example, we may alter or remove certain
            features. We also reserve the right to prevent you from using the
            service at any time. Any reliance you place on the availability or
            timeliness of this service is at your own risk and responsibility.
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">Content</h2>
          <p className="policy-list__section">
            You are solely responsible for the content and material you publish
            on this service. Any content or material you publish on this website
            may be seen by others: never publish anything confidential,
            sensitive or personally-identifying, or any other content or
            material to which your employer prohibits or ought to prohibit you
            from publishing or otherwise disclosing.
          </p>
          <p className="policy-list__section">
            We reserve the right, but not the obligation, to alter or remove any
            content or material published on the service
          </p>
          <p className="policy-list__section">
            All information and all personal data not connected with data
            collecting but used in Sprint Retrospective Tool by using our
            services will be not secured by us in case of sharing links and
            giving access by you or by anyone which had access to this
            information given by you.
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">Data shared with 3rd Parties</h2>
          <p className="policy-list__section">
            To provide our services we need to share some of your Data with
            third parties. However, we only share the information if compliant
            to the following conditions:
          </p>
          <ol className="policy-sublist policy-sublist--alpha">
            <li>with a legitimate purpose;</li>
            <li>for specified reason;</li>
            <li>
              only to those who are compliant to GDPR, having appropriate
              privacy and security procedures.
            </li>
          </ol>
          <p className="policy-list__section">
            We also use other third parties, which we don’t directly provide
            information. Nevertheless, they only collect Data from our users to
            provide their services.
          </p>
          <ol className="policy-sublist policy-sublist--dots">
            <li>
              <label className="policy-sublist__bold">Google Analytics</label>–
              We use Google Analytics to collect and analyse Website usage to
              improve our Services;
            </li>
            <li>
              <label className="policy-sublist__bold">Hotjar</label>- We use
              Hotjar to track heatmaps (move, scroll and clicks heatmaps) to
              improve our Services;
            </li>
            <li>
              <label className="policy-sublist__bold">Facebook,</label>
              <label className="policy-sublist__bold">Twitter,</label>
              <label className="policy-sublist__bold">Linkedin.</label>
            </li>
          </ol>
          <p className="policy-list__section">
            Once you start using our services, you will be giving us permission
            to share your data with the third parties above.
          </p>
          <p className="policy-list__section">
            If we eventually need share your personal data with an entity that
            were not listed above, we will inform and ask your permission.{' '}
          </p>
          <p className="policy-list__section">
            We clarify that we can not be responsible for the attacks of hackers
            or any other abnormality related to the reach of the international
            standards of performance and security of the internet.{' '}
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">Restrictions</h2>
          <p className="policy-list__section">
            You may not, and shall not allow a Sprint Retrospective Tool user or
            any third party to; (i) give, sell, rent, lease, timeshare,
            sublicense, disclose, publish, assign, market, sell, display,
            transmit, transfer or distribute any portion of our Services; (ii)
            disable or interfere with our security-related features; (iii)
            reverse engineer, decompile or disassemble, decrypt or, attempt to
            derive the source code of our Services; (iv) copy, modify, improve,
            change or create any derivate work of our Services; (v) remove or
            alter Sprint Retrospective Tool or any third party’s identification,
            attribution or copyright notices, trademarks, or other proprietary
            rights joint to or provided as part of our Services.
          </p>
          <p className="policy-list__section">
            By accepting this Privacy Policy you become aware that Sprint
            Retrospective Tool is not responsible for any images or comments
            made by its users with illegal, violent, criminal, racist, sexist or
            xenophobic content.
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">How we stored Data</h2>
          <p className="policy-list__section">
            We use procedures of security to your Data collected to guarantee
            your rights of confidentiality. All Data is processed and dealt in a
            restricted and careful manner with appropriate with the GDPR.
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">
            How to verify, modify or delete your Data
          </h2>
          <p className="policy-list__section">
            In case of gathering by us any Personal Data you will have full
            control over your Data and personal information in our Sprint
            Retrospective Tool. In other words, you can verify which Personal
            Data is stored on your behalf, you can ast to modified it, corrected
            it or deleted by us, please contact our e-mail{' '}
            <label className="policy-sublist__bold">hello@boldare.com</label>,
            giving a clear description of your request.
          </p>
          <p className="policy-list__section">
            You have rights related to Personal Data, as described below:
          </p>
          <ol className="policy-sublist policy-sublist--dots">
            <li>Edit your Personal Data and make sure it’s updated;</li>
            <li>Access updated Personal Data held by us;</li>
            <li>Request deletion your Personal Data;</li>
          </ol>
          <p className="policy-list__section">
            You can demand it through sending us email{' '}
            <label className="policy-sublist__bold">hello@boldare.com</label>,
            and we will guarantee them.
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">Governing Law</h2>
          <p className="policy-list__section">
            These terms shall be governed by and construed in accordance with
            the laws of Poland under the exclusive jurisdiction of the Polish
            Courts.
          </p>
          <p className="policy-list__section">
            If any term or condition is found to be unfair, unenforceable or
            illegal by a court of competent jurisdiction, then that term or
            condition shall be severed as if it was never contained in the
            agreement, and the remaining terms and conditions shall continue in
            full force and effect, and continue to be fully binding and
            enforceable.
          </p>
        </li>
        <li>
          <h2 className="policy-list__header">Contact Information</h2>
          <p className="policy-list__section">
            You can reach us by using our mail address{' '}
            <label className="policy-sublist__bold">hello@boldare.com</label>,
          </p>
          <p className="policy-list__section">
            <label className="policy-sublist__bold">Boldare</label> operated by:
          </p>
          <ol className="policy-sublist policy-sublist--company">
            <li>
              XSolve Sp. z o.o., 44-100 Gliwice, ul. Królowej Bony 13,
              Registration number 0000308949
            </li>
            and
            <li>
              Chilid Sp. z o.o., 44-10 Gliwice, ul. Królowej Bony 13,
              Registration number 0000511773
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
);

export default PolicyPage;
