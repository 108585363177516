import React from 'react';
import { connect } from 'react-redux';
import * as boardAction from 'root/store/actions/board';

import CardView from 'components/cardView';
import { handlers } from 'root/utils';
import CardVotes from 'components/cardVote';
import Button from 'components/button';
import ConfirmPopup from 'components/popup';
import { getActiveVotes } from './utils';

import 'components/card/card.scss';

const Card = ({
  colorClass,
  cardsCount,
  voteCount,
  cardId,
  boardId,
  columnId,
  readOnly,
  text,
  authors,
  columnIdForMerge,
  cardIdForMerge,
  chooseCardIdForMerge,
  indexInList,
  votes,
  editedBy,
}) => {
  const apiUpdate = (cardText) => {
    handlers.cardUpdate(boardId, columnId, cardId, cardText);
    handlers.cardStopEditing(boardId, columnId, cardId);
  };

  const apiRemove = () => handlers.cardRemove(boardId, columnId, cardId);
  const apiMerge = () =>
    handlers.mergeCards(boardId, columnId, cardIdForMerge, cardId);

  const handleClick = () => {
    if (!cardIdForMerge) {
      chooseCardIdForMerge(cardId, columnId);
    } else if (cardIdForMerge === cardId) {
      chooseCardIdForMerge('', '');
    } else {
      apiMerge();
    }
  };

  const getButtonText = () => {
    const useCancel = cardIdForMerge && cardIdForMerge === cardId;

    if (cardIdForMerge && columnId === columnIdForMerge) {
      if (useCancel) {
        return 'Cancel';
      }
      return 'With';
    }
    return 'Merge';
  };

  const activeUserVotes = getActiveVotes(votes, boardId);
  const disabledButton = columnIdForMerge && !(columnIdForMerge === columnId);

  return (
    <>
      {text && (
        <CardView
          boardId={boardId}
          colorClass={colorClass}
          onCancel={apiRemove}
          onSubmit={apiUpdate}
          buttonText="edit"
          defaultText={text}
          cardId={cardId}
          columnId={columnId}
          authors={authors}
          indexInList={indexInList}
          editedBy={editedBy}
        >
          {!readOnly &&
            cardsCount > 1 &&
            (getButtonText() === 'With' ? (
              <ConfirmPopup
                onApprove={handleClick}
                text="Are you sure you want to merge these cards?"
                trigger={
                  <Button className="button card__actions--button button--small button--white">
                    {getButtonText()}
                  </Button>
                }
              />
            ) : (
              <Button
                className="button card__actions--button button--small button--white"
                onClick={handleClick}
                disabled={disabledButton}
              >
                {getButtonText()}
              </Button>
            ))}
          <CardVotes
            cardId={cardId}
            voteCount={voteCount}
            activeUserVotes={activeUserVotes}
            boardId={boardId}
            columnId={columnId}
          />
        </CardView>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cardIdForMerge: state.board.cardIdForMerge,
  columnIdForMerge: state.board.columnIdForMerge,
  readOnly: state.visible.readOnly,
});

const mapDispatchToProps = (dispatch) => ({
  chooseCardIdForMerge: (cardIdForMerge, columnIdForMerge) =>
    dispatch(
      boardAction.chooseCardIdForMerge(cardIdForMerge, columnIdForMerge)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Card);
