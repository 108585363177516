import React from 'react';
import Popup from 'reactjs-popup';
import Button from 'components/button';
import 'components/popup/popup.scss';

const InformationPopup = () => (
  <Popup className="popup-content--yellow" open modal>
    {(close) => (
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title new-page-info">
            <p>This page will be disabled on 30.09.2020!</p>
          </p>
          <Button className="delete" aria-label="close" onClick={close} />
        </header>
        <footer className="modal-card-foot">
          <Button
            className="button--medium button--yellow"
            onClick={() => {
              close();
            }}
          >
            Ok
          </Button>
          <a href="https://boards.boldare.com/" rel="noopener noreferrer">
            <Button
              className="button--medium button--yellow"
              onClick={() => {
                close();
              }}
            >
              Go to the new version now
            </Button>
          </a>
        </footer>
      </div>
    )}
  </Popup>
);

export default InformationPopup;
