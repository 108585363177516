import React, { memo, useEffect, useState } from 'react';
import { getRemainingVotes } from 'root/components/card/utils';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

const VoteNotification = memo(({ votesPerUser, usedVotesPerActiveUser }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      return setIsLoaded(true);
    }

    if (isLoaded && votesPerUser >= usedVotesPerActiveUser) {
      toast.info(
        `Remaining votes: ${getRemainingVotes(
          votesPerUser,
          usedVotesPerActiveUser
        )}`
      );
    }
  }, [votesPerUser, usedVotesPerActiveUser]);

  return <div />;
});

VoteNotification.displayName = 'VoteNotification';

const mapStateToProps = (state) => ({
  votesPerUser: state.board.votesPerUser,
  usedVotesPerActiveUser: state.board.usedVotesPerActiveUser,
});

export default connect(mapStateToProps)(VoteNotification);
