import React from 'react';
import { withRouter } from 'react-router-dom';
import Link from 'components/link';
import { connection, isFullAppVersion } from 'root/utils';
import { LIMIT_VOTES_PER_USER } from 'root/constants';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Button from 'components/button';
import LoginByGoogle from 'components/loginByGoogle';
import InputVotes from './InputVotes';
import 'components/entryForm/entryForm.scss';
import InformationPopup from '../modalNewPageInfo';

class EntryForm extends React.Component {
  state = {
    boardName: '',
    boardMaxVotes: isFullAppVersion() ? '' : 5,
  };

  componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const boardName = params.get('boardName');

    if (boardName) {
      if (isFullAppVersion()) {
        this.setState({ boardName });
      } else {
        const { boardMaxVotes } = this.state;

        connection.createBoard({ name: boardName, maxVotes: boardMaxVotes });
        connection.on('boardCreated', this._boardCreated);
      }
    }
  }

  componentWillUnmount() {
    connection.off('boardCreated', this._boardCreated);
  }

  setMaxVotes = (boardMaxVotes) => {
    this.setState({ boardMaxVotes });
  };

  handleNameChange = ({ target: { value } }) => {
    this.setState({ boardName: value });
  };

  apiCreateBoard = (e) => {
    const { boardName, boardMaxVotes } = this.state;

    if (boardName && boardMaxVotes) {
      connection.createBoard({ name: boardName, maxVotes: boardMaxVotes });
      connection.on('boardCreated', this._boardCreated);
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this._validateFields()) {
      this.apiCreateBoard(e);
    }
  };

  _maxBoardNameLength = 300;

  _validateFields = () => {
    const { boardName, boardMaxVotes } = this.state;
    if (!boardName.trim()) {
      toast.warn('You have to fill board name field');
      return false;
    }
    if (boardName.length > this._maxBoardNameLength) {
      toast.warn(
        `Board name should have no more than ${this._maxBoardNameLength} characters`
      );
      return false;
    }
    if (boardMaxVotes <= 0 || boardMaxVotes >= LIMIT_VOTES_PER_USER) {
      toast.warn(
        `Votes number should be greater than 0 and less than ${LIMIT_VOTES_PER_USER}`
      );
      return false;
    }
    return true;
  };

  _boardCreated = ({ id }) => {
    this.props.history.push({
      pathname: `/board/${id}`,
      state: id,
    });
  };

  render() {
    const { isUserLoggedIn } = this.props;
    const { boardName, boardMaxVotes } = this.state;
    const params = new URLSearchParams(this.props.location.search);
    const boardNameParam = params.get('boardName');

    if (
      !isFullAppVersion() &&
      (boardNameParam === null ||
        !!(typeof boardNameParam === 'string' && boardNameParam.length === 0))
    ) {
      window.location.href = process.env.LANDING_PAGE_URL;
      return null;
    }

    return (
      <div className="entry-form">
        <div className="box">
          <form onSubmit={this.onSubmit}>
            <label className="label entry-form__title">
              Create new retro board
            </label>
            <div className="field">
              <div className="control">
                <input
                  className="input entry-form__input"
                  type="text"
                  value={boardName}
                  placeholder="Board name"
                  onChange={this.handleNameChange}
                  maxLength={this._maxBoardNameLength}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <InputVotes
                  maxVotes={boardMaxVotes}
                  setMaxVotes={this.setMaxVotes}
                />
              </div>
            </div>

            <div className="field entry-form__create-button">
              <Button className="button--medium button--white" type="submit">
                Create board
              </Button>
            </div>
          </form>
        </div>
        {isUserLoggedIn && (
          <div className="field entry-form__history-button">
            <Link className="label" to="/history">
              Historical Boards
            </Link>
            <Link className="label" to="/archive">
              Archive
            </Link>
          </div>
        )}
        {isFullAppVersion() && (
          <LoginByGoogle buttonClassNames="login-button button--medium button--yellow button--bold" />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isUserLoggedIn: state.auth.isUserLoggedIn,
});

export default connect(
  mapStateToProps,
  null
)(withRouter(EntryForm));
