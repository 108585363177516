import React from 'react';
import Link from 'components/link';
import { apiAuthorizedCall } from 'root/utils';

const Boards = React.memo((props) => {
  const {
    id,
    activeBoardId,
    name,
    removeBoardFromList,
    handleBoardClick,
  } = props;

  const handleDeleteBoard = async () => {
    const response = await apiAuthorizedCall(
      `/api/archive/deleteBoard?boardId=${id}`,
      'delete'
    );
    if (response.ok) {
      removeBoardFromList(id);
      handleBoardClick(id);
    }
  };

  const boardClick = () => {
    handleBoardClick(id);
  };

  return (
    <div
      className={`archive-page__link ${activeBoardId === id ? 'active' : ''}`}
      onClick={boardClick}
    >
      <div className="archive-page__board-name--wrapper">
        <i className="fas fa-file archive-page__icon" />
        <p>{name}</p>
      </div>
      <div className="archive-page__action-container">
        <div
          className="archive-page__icon--wrapper"
          onClick={handleDeleteBoard}
        >
          <i className="fas fa-trash-alt" title="Delete" />
        </div>
        <Link
          className="archive-page__icon--wrapper"
          to={`/archiveBoard/${id}`}
          key={id}
        >
          <i className="fas fa-arrow-right" title="Visit" />
        </Link>
      </div>
    </div>
  );
});

Boards.displayName = 'Boards';

export default Boards;
