import React from 'react';

import 'components/button/button.scss';

const Button = React.memo(({ children, className, type, onClick, ...rest }) => (
  <button type={type} className={className} onClick={onClick} {...rest}>
    {children}
  </button>
));

Button.defaultProps = {
  type: 'button',
};

Button.displayName = 'Button';
export default Button;
