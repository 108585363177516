import React from 'react';
import CardsList from 'components/cardsList';
import ColumnTitle from 'components/columnTitle';
import ConfirmPopup from 'components/popup';
import { connect } from 'react-redux';
import { handlers } from 'root/utils';
import 'components/column/column.scss';

const Column = ({
  columnId,
  color,
  handleRemove,
  moveColumnId,
  readOnly,
  boardId,
  moveCardId,
  leftIndex,
  rightIndex,
  usersCurrentlyAddingCards,
}) => {
  const moveLeft = () => handlers.setColumnOrder(boardId, columnId, leftIndex);
  const moveRight = () =>
    handlers.setColumnOrder(boardId, columnId, rightIndex);
  const onHandleRemove = () => handleRemove(columnId);
  const onHandleMove = () => {
    handlers.cardChangeColumn(boardId, moveColumnId, moveCardId, columnId);
  };

  return (
    <div className={`column retro-column retro-column--${color}`}>
      <div className="retro-column__head-wrapper">
        <ColumnTitle columnId={columnId} />
        {!readOnly && (
          <div className="column_options">
            {moveColumnId && moveColumnId !== columnId && (
              <div className="column__action" onClick={onHandleMove}>
                <i
                  className="fas fa-caret-square-down card__action--icon"
                  title="Move card here"
                />
              </div>
            )}
            {leftIndex != null && (
              <div className="column__action" onClick={moveLeft}>
                <i
                  className="fas fa-arrow-left card__action--icon"
                  title="Move column left"
                />
              </div>
            )}
            <ConfirmPopup
              onApprove={onHandleRemove}
              text="Are you sure you want to delete this column?"
              trigger={
                <div className="column__action">
                  <i
                    className="fas fa-trash-alt card__action--icon"
                    title="Remove column"
                  />
                </div>
              }
            />
            {rightIndex != null && (
              <div className="column__action" onClick={moveRight}>
                <i
                  className="fas fa-arrow-right card__action--icon"
                  title="Move column right"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <CardsList
        columnId={columnId}
        usersCurrentlyAddingCards={usersCurrentlyAddingCards}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  moveColumnId: state.board.moveColumnId,
  boardId: state.board.boardId,
  moveCardId: state.board.moveCardId,
  readOnly: state.visible.readOnly,
});

export default connect(
  mapStateToProps,
  null
)(Column);
